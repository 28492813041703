#memberships {
  legend {
    position: relative;
    .edit{
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 5%;
    }
  }
  fieldset {
    .content {
      border: solid 1px red;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .dates, .date_paiement, .method_paiement{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .content.green{
      border: solid 1px green!important;
    }
  }
}


#ajax_render_details{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0,0,0,0.7);
  .cotisation_content{
    width: 80vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    .closed{
      position: absolute;
      top: 5px;
      right: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}