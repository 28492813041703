table .btn-sm{
    line-height: 1.2;
}
.btn-er{
    background: #fff;
    color: #c82333;
    border: solid 1px #c82333!important;
}
.btn-er:hover{
    background: #c82333;
    color: #fff;
    border: none;
    box-shadow:none;
}

.btn-close{
    color: #FF8C00;
    background: #fff;
    border: solid 1px #FF8C00!important;
}
.btn-close:hover{
    background: #FF8C00;
    color: #fff;
    border: none;
    box-shadow:none;
}

.btn-reset  {
    color: #17a2b8;
    background: #fff;
    border: solid 1px #17a2b8!important;
}
.btn-reset:hover{
    background: #17a2b8;
    color: #fff;
    border: none;
    box-shadow:none;
}


.btn-next,
.btn-see,
/*.btn-success,*/
.btn-ajout  {
    color: #28a745;
    background: #fff;
    border: solid 1px #28a745!important;
}
.btn-next:hover,
.btn-see:hover ,
.btn-ajout:hover{
    background: #28a745;
    color: #fff;
    border: none;
    box-shadow:none;
}

.btn-cxl  {
    color: #8B0000;
    background: #fff;
    border: solid 1px #8B0000!important;
}
.btn-cxl:hover{
    background: #8B0000;
    color: #fff;
    border: none;
    box-shadow:none;
}

.btn-edit  {
    color: #D2691E;
    background: #fff;
    border: solid 1px #D2691E!important;
}
.btn-edit:hover{
    background: #D2691E;
    color: #fff;
    border: none;
    box-shadow:none;
}

.btn-reload  {
    color: #17a2b8  ;
    background: #fff;
    border: solid 1px #17a2b8   !important;
}
.btn-reload:hover{
    background: #17a2b8 ;
    color: #fff;
    border: none;
    box-shadow:none;
}

.btn-see-orange  {
    color: #D2691E;
    background: #fff;
    border: solid 1px #D2691E!important;
}
.btn-see-orange:hover{
    background: #D2691E;
    color: #fff;
    border: none;
    box-shadow:none;
}

.email_drop{
    width: 100% !important;
    padding-left: 10px!important;
    color: #17a2b8!important;
}
.email_drop:hover{
    color: #fff!important;
    text-decoration: none;
}

.btn-secondary{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-secondary:hover{
    color: #ffffff;
    background: #6c757d
}

.btn-rgpd{
    color: #dc3545;
}
#close_preview{
    cursor: pointer;
}

.btn-dpm{
    color: $dpm_color;
    background-color: #ffffff;
    border-color: $dpm_color;
}
.btn-dpm:hover{
    color: $dpm_color;
    background: #ffffff
}
.delete_project_document,
.delete_project{
    cursor: pointer;
}

.btn-show{
    color: #bfbfbf;
    background: #fff;
    border: solid 1px #bfbfbf!important;
}
.btn-show:hover{
    background: #bfbfbf;
    color: #fff;
    border: none;
    box-shadow:none;
}