.home_header{
}
.home_header>div{
  display: flex;
  align-items: center;
}
.home_header h1{
  font-size: 1.2rem!important;
  margin: 0;
}
.home_header  h2{
  font-size: 1.5rem!important;
  margin: 0 auto;
}
.home_header .logo_home img{
  width: 100%;
}
.stats {
  .last_five {
    .titre.h5 {
      h5{
        margin: 0 auto;
        height: 30px;
        font-size: 1.5rem;
        color: #666;
        font-weight: bold;
      }
      display: flex;
      align-items: flex-end;
      margin: 0;
    }
    .content {
      .last_content {
        border: solid 1px $dpm_color;
        width: 100%;
        margin: 5px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        border-radius: 5px;
        max-height: 35px;
        div {
          width: calc(100% / 5);
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          padding: 1%;

          &:not(:first-child) {
            text-align: center;
            i {
              margin-left: 5px;
            }
          }
          &:first-child {
            i {
              margin-right: 5px;
            }
          }
          i {
            font-size: 14px;
          }
          @include media-breakpoint-down(md) {

            &:last-child {
              width: 11%;
            }
            &:nth-child(4) {
              width: 11%;
            }
            &:first-child {
              width: 45%;
            }
          }
        }

      }
      height: calc(100% - 40px);
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.tab {
  cursor: pointer;
  h2 {
    padding: 10px 0;
    position: relative;
    &:after {
      transition: all ease-in-out .2s;
      background: none repeat scroll 0 0 $dpm_color;
      content: "";
      display: block;
      height: 1px;
      width: 0;
      position: absolute;
      bottom: 0;
    }
  }
  &:hover {
    h2 {
      &:after {
        width: 100%;
      }
    }
  }
}
.tab.active {
  h2 {
    &:after {
      width: 100%;
    }
  }
}

.animal_content {
  .tabi {
    .nav-tabs {
      border: none;
      border-bottom: 1px solid #079fc9;
      margin: 0;
      li {
        display: flex;
        a {
          cursor: pointer;
          padding: 10px 20px;
          margin: 0 10px 0 0;
          font-weight: 600;
          text-transform: capitalize;
          border: 1px solid #e6e5e1;
          border-bottom: none;
          border-radius: 5px 5px 0 0;
          z-index: 1;
          position: relative;
          transition: all 0.3s ease 0s;

          &:hover {
            background: #fff;
            border: 1px solid #079fc9;
            margin: 0 10px -1px 0;

            &:before {
              transform: scaleX(1);
            }
          }

          &:before {
            content: "";
            display: block;
            height: 1px;
            background: #fff;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out 0s;
          }
        }
      }
      li.active a {
        background: #fff;
        border: 1px solid #079fc9;
        margin: 0 10px -1px 0;
        &:before {
          transform: scaleX(1);
        }
      }
    }
    .tab-content {
      padding: 10px;
    }
  }
}
@media only screen and (max-width: 479px) {
  .animal_content {
    .tabi {
      .nav-tabs {
        border: none;
        li {
          a {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
            border-bottom: 1px solid transparent;

            &:before {
              content: "";
              width: 100%;
              height: 1px;
              background: #079fc9;
              position: absolute;
              bottom: -1px;
              left: 0;
            }
          }
        }
      }
    }
  }
}
