.table-wrapper-responsive{
  max-width: 100%;
  overflow: auto;
}


table.sortable tr{
  cursor: move;
}

.table-thumbnail{
  max-width: 150px;
}