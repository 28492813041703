$main_color: #0056be;
$main_color_lighter: #2b7df6;

$txt_color_light: #FFFFFF;
$txt_color_dark: #333333;
//$txt_color_dark: #000000;

$errorColor: red;
$validColor: green;
$updated: yellow;
$errorColorDpm : #8b0000;
$greenDpm : #3cb371;

$font_size_base: calc(1em + 0.2vw);

$dpm_color: #59BDBD;
$logistic_color: #da70d6;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;


$course_color: #f8aa6a
