

#organisme_checklist.normal {
  position: relative;
  background: white;
  .checklist_details>div:hover {
    background: $dpm_color;
    color: white!important;
  }
  .mandatory{
    border: solid 1px #c82333;
    padding: 2px 5px;
    margin-left: 5px;
    border-radius: 10px;
    color: #c82333;
  }
  .wrapper {
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    position: relative;

    a {
      color: $txt_color_dark!important;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding-top: 10px;
      h3 {
        font-size: 25px!important;;
      }
      .content {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
        position: relative;
        border-bottom: solid 1px $dpm_color;
        .titre {
          font-size: 20px;
        }
        .text {
          font-size: 14px
        }
      }
    }
  }
}
#minimise_organisme_checklist {
  .green {
    margin-top: 15px;
  }
  .progresse{
    margin: auto;
  }
  .green .progresse,
  .red .progresse,
  .orange .progresse {
    position: relative;
    border-radius: 50%;
  }

  .green .progresse,
  .red .progresse,
  .orange .progresse {
    width: 110px;
    height: 110px;
  }

  .green .progresse {
    border: 5px solid #53fc53;
  }

  .green .progresse {
    box-shadow: 0 0 20px #029502;
  }

  .green .progresse,
  .red .progresse,
  .orange .progresse {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .green .progresse .inner,
  .red .progresse .inner,
  .orange .progresse .inner {
    position: absolute;
    overflow: hidden;
    z-index: 2;
    border-radius: 50%;
  }

  .green .progresse .inner,
  .red .progresse .inner,
  .orange .progresse .inner {
    width: 100px;
    height: 100px;
  }

  .green .progresse .inner,
  .red .progresse .inner,
  .orange .progresse .inner {
    border: 5px solid white;
  }

  .green .progresse .inner,
  .red .progresse .inner,
  .orange .progresse .inner {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .green .progresse .inner .water,
  .red .progresse .inner .water,
  .orange .progresse .inner .water {
    position: absolute;
    z-index: 1;
    width: 200%;
    height: 200%;
    left: -50%;
    border-radius: 40%;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: spin;
    animation-name: spin;
  }

  .green .progresse .inner .water {
    top: 25%;
  }

  .green .progresse .inner .water {
    background: rgba(83, 252, 83, 0.5);
  }

  .green .progresse .inner .water,
  .red .progresse .inner .water,
  .orange .progresse .inner .water {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .green .progresse .inner .water,
  .red .progresse .inner .water,
  .orange .progresse .inner .water {
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
  }

  .green .progresse .inner .water {
    box-shadow: 0 0 20px #03bc03;
  }

  .green .progresse .inner .glare,
  .red .progresse .inner .glare,
  .orange .progresse .inner .glare {
    position: absolute;
    top: -120%;
    left: -120%;
    z-index: 5;
    width: 200%;
    height: 200%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 50%;
  }

  .green .progresse .inner .glare,
  .red .progresse .inner .glare,
  .orange .progresse .inner .glare {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .green .progresse .inner .glare,
  .red .progresse .inner .glare,
  .orange .progresse .inner .glare {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .green .progresse .inner .percent,
  .red .progresse .inner .percent,
  .orange .progresse .inner .percent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: bold;
    text-align: center;
  }

  .green .progresse .inner .percent,
  .red .progresse .inner .percent,
  .orange .progresse .inner .percent {
    line-height: 90px;
    font-size: 25px;
  }

  .green .progresse .inner .percent {
    color: #03c603;
  }

  .green .progresse .inner .percent {
    text-shadow: 0 0 10px #029502;
  }

  .green .progresse .inner .percent,
  .red .progresse .inner .percent,
  .orange .progresse .inner .percent {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .red {
    margin-top: 15px;
  }

  .red .progresse {
    border: 5px solid #ed3b3b;
  }

  .red .progresse {
    box-shadow: 0 0 20px #7a0b0b;
  }

  .red .progresse .inner .water {
    top: 75%;
  }

  .red .progresse .inner .water {
    background: rgba(237, 59, 59, 0.5);
  }

  .red .progresse .inner .water {
    box-shadow: 0 0 20px #9b0e0e;
  }

  .red .progresse .inner .percent {
    color: #a30f0f;
  }

  .red .progresse .inner .percent {
    text-shadow: 0 0 10px #7a0b0b;
  }

  .orange {
    margin-top: 15px;
  }

  .orange .progresse {
    border: 5px solid #f07c3e;
  }

  .orange .progresse {
    box-shadow: 0 0 20px #7e320a;
  }

  .orange .progresse .inner .water {
    top: 50%;
  }

  .orange .progresse .inner .water {
    background: rgba(240, 124, 62, 0.5);
  }

  .orange .progresse .inner .water {
    box-shadow: 0 0 20px #a0400c;
  }

  .orange .progresse .inner .percent {
    color: #a8430d;
  }

  .orange .progresse .inner .percent {
    text-shadow: 0 0 10px #7e320a;
  }

  #copyright {
    margin-top: 25px;
    background-color: transparent;
    font-size: 14px;
    color: #b3b3b3;
    text-align: center;
  }

  #copyright div {
    margin-bottom: 10px;
  }

  #copyright a,
  #copyright a:link {
    color: #808080;
    text-decoration: none;
    border-bottom: 1px solid #808080;
    padding-bottom: 2px;
  }

  #copyright a:active {
    color: #b3b3b3;
  }

  #copyright a:hover {
    color: #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 4px;
  }

  .instructions {
    display: inline-block;
    margin: 5px 0;
    font-size: 16px;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


}







