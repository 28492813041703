#crud {
  form {
    .validation{
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 12;
    }
    .content {
      overflow: auto;

      thead {
        tr {
          box-shadow: 0 3px 3px #999;

          th {
            position: sticky;
            top: 0;
            background: white;
            z-index: 10;
            box-shadow: 3px 3px 3px #999;
          }
        }
      }
      input:hover{
        cursor: pointer
      }
    }
  }
}