/*********************************************
OVERRIDE DE HCOFFCANVAS POUR VERSION DESKTOP
 */
#menu-sidebar {
  display: none;
}

.hc-offcanvas-nav {
  //z-index: 0!important;
  .nav-close {
    a:hover {
      color: #FFFFFF;
    }
  }

  .nav-container {
    height: 100vh;
    //height: calc(100vh - 80px);
    overflow: auto;
    @include media-breakpoint-down(md) {
      margin-top: 0;
      //height: calc(100vh - 80px);
    }
  }

  .nav-wrapper-2 {
    a {
      padding: 14px 17px 14px 27px;
    }
  }
}

.hc-nav-2 {
  left: 100px;
  position: fixed;
}

.hc-nav-2 > span:before,
.hc-nav-2 > span:after,
.hc-nav-2 > span {
  background: #8b0000 !important;
}


/** MD et au dessus **/
@include media-breakpoint-up(lg) {
  nav.hc-offcanvas-nav {
    visibility: visible;
  }
  .hc-nav-trigger.hc-nav-1 {
    display: none !important;
  }
  .hc-offcanvas-nav.hc-nav-1.nav-position-left .nav-container {
    transform: translate3d(0, 0, 0) !important;
  }
  .hc-offcanvas-nav .nav-close {
    display: none;
  }
}


.nav-container {
  margin-top: 80px;
  box-shadow: 10px 10px 20px #00000029;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

.page-container .container {
  margin-top: 10px !important;
  border-radius: 0 !important;
  box-shadow: none;
  padding-bottom: 30px;
}

.page-container {
  padding-top: 20px;
  min-height: 100vh;
  //background: white;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  padding-left: 250px;
  padding-right: 10px;
  @include media-breakpoint-down(md) {
    padding-left: 10px;

  }
}

#config-wrapper .container {
  max-width: unset;
  min-height: 100vh;
}

#edit_gender {
  /*   display: flex; */
  padding: 25px 0 0;
  display: inline-flex
}

#edit_gender .radio input {
  position: absolute;
  left: -20px;
}

#edit_gender .radio label {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 10px;
}


#config-wrapper>div>.container{
  border: solid #8b0000  1px;
}