
.edit_mdp {
  color: #8b0000;
  border: solid 1px #8b0000;
  transition: all 0.2s ease-in;
}

#organisme {
  .logo-organisme {
    position: absolute;
    top: 0;
    right: 15px;
    max-height: 100px
  }

  .btn{
    font-weight: bold;
  }
  #accordion {
    .panel {
      border-radius: 0;
      border: 0;
      margin-top: 0;
    }
    .row_delete {
      position: static
    }
    a {
      display: block;
      padding: 15px 10px;
      border: solid 1px #8b0000;
      text-decoration: none;
      color: #8b0000;
    }
    a.btn  {
      padding: 5px 0;
    }

    .panel-heading {
      padding: 0;
      border-radius: 0;
      text-align: left;
      a{
        text-transform: uppercase;
      }
      a.collapsed:hover, a.collapsed:focus {
        transition: all 0.2s ease-in;
        color: #8b0000;
      }

      a:not(.collapsed) {
        color: #8b0000;
        border: solid 1px #8b0000;
        transition: all 0.2s ease-in;
      }

      .accordion-toggle {
        text-align: left;
        cursor: pointer;
      }

      .accordion-toggle::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f00d";
        float: right;
        color: #8b0000;
        transform: rotate(0deg);
        transition: all 0.2s ease-in;
      }
      .accordion-toggle.collapsed::before {
        color: #8b0000;
        transform: rotate(-135deg);
        transition: all 0.2s ease-in;
      }
    }
  }
}