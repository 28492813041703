.checkbox-inline .checkbox {
  display: inline-block;
}

.checkbox label .toggle, .checkbox-inline .toggle {
  margin-left: 0!important;
}

.check {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.checkbox-inline {
  #organisme_edit_animalType {
    label {
      display: flex !important;
      align-items: center;
      margin-right: 10px
    }
  }
}

