@import "../variables";
/*********************** couleur DEFAULT ***********************/
fieldset {
  margin: 0;
  padding: 5px;
  //border: solid 1px $txt_color_dark;
  border-top: none;
  border-radius: 0 0 5px 5px;

  &.fieldset_blue {
    border: solid 1px $dpm_color;
    border-top: none;
    padding: 10px;
  }
}

legend {
  margin: 15px 0 0 0;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px $txt_color_dark;
  border-radius: 5px;

  //&:after {
  //  position: absolute;
  //  right: 15px;
  //  color: #333;
  //  height: 20px;
  //  width: 20px;
  //  border: solid 1px #333;
  //  border-top: none;
  //  border-left: none;
  //  rotate: 45deg;
  //}
}
legend.main{
  border-color: rgba(23, 162, 184, 1);
}

legend.legend_blue.cross {
  &:after {
    content: " ";
    height: 25px;
    width: 25px;
    top: 45%;
    transform: translate(-50%, -50%);
  }
}

legend.active {
  &:after {
    content: " ";
    top: 15px!important;
    transform: rotate(-180deg)  translate(0,0) !important;
  }

  border: solid 1px $txt_color_dark;
  border-radius: 5px 5px 0 0;
}


legend.blue_border.cross{
  border: solid 1px $dpm_color!important;
}
legend.active.blue_border {
  border: solid 1px $dpm_color!important;
}

legend.orange_border.cross{
  border: solid 1px $course_color!important;
}
legend.active.orange_border {
  border: solid 1px $course_color!important;
}