/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 10, 2019 */

@font-face {
    font-family: 'overpassheavy';
    src: url('../fonts/overpass/overpass-heavy-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpassheavy_italic';
    src: url('../fonts/overpass/overpass-heavy-italic-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-heavy-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpassitalic';
    src: url('../fonts/overpass/overpass-italic-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpasslight';
    src: url('../fonts/overpass/overpass-light-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpasslight_italic';
    src: url('../fonts/overpass/overpass-light-italic-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-light-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpassregular';
    src: url('../fonts/overpass/overpass-regular-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpasssemibold';
    src: url('../fonts/overpass/overpass-semibold-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpasssemibold_italic';
    src: url('../fonts/overpass/overpass-semibold-italic-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-semibold-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpassthin';
    src: url('../fonts/overpass/overpass-thin-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'overpassthin_italic';
    src: url('../fonts/overpass/overpass-thin-italic-webfont.woff2') format('woff2'),
    url('../fonts/overpass/overpass-thin-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'saira_semicondensedthin';
    src: url('../fonts/saira/sairasemicondensed-thin-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedThIt';
    src: url('../fonts/saira/sairasemicondensed-thinitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairathin';
    src: url('../fonts/saira/saira-thin-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairathin_italic';
    src: url('../fonts/saira/saira-thinitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondenseditalic';
    src: url('../fonts/saira/sairasemicondensed-italic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedlight';
    src: url('../fonts/saira/sairasemicondensed-light-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedLtIt';
    src: url('../fonts/saira/sairasemicondensed-lightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedmedium';
    src: url('../fonts/saira/sairasemicondensed-medium-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedMdIt';
    src: url('../fonts/saira/sairasemicondensed-mediumitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedregular';
    src: url('../fonts/saira/sairasemicondensed-regular-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedsemibold';
    src: url('../fonts/saira/sairasemicondensed-semibold-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedSBdIt';
    src: url('../fonts/saira/sairasemicondensed-semibolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedblack';
    src: url('../fonts/saira/sairasemicondensed-black-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedBlkIt';
    src: url('../fonts/saira/sairasemicondensed-blackitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedbold';
    src: url('../fonts/saira/sairasemicondensed-bold-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedBdIt';
    src: url('../fonts/saira/sairasemicondensed-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedextrabold';
    src: url('../fonts/saira/sairasemicondensed-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedXBdIt';
    src: url('../fonts/saira/sairasemicondensed-extrabolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedextralight';
    src: url('../fonts/saira/sairasemicondensed-extralight-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_semicondensedXLtIt';
    src: url('../fonts/saira/sairasemicondensed-extralightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairasemicondensed-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairaextralight';
    src: url('../fonts/saira/saira-extralight-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairaextralight_italic';
    src: url('../fonts/saira/saira-extralightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairaitalic';
    src: url('../fonts/saira/saira-italic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairalight';
    src: url('../fonts/saira/saira-light-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairalight_italic';
    src: url('../fonts/saira/saira-lightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairamedium';
    src: url('../fonts/saira/saira-medium-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairamedium_italic';
    src: url('../fonts/saira/saira-mediumitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairaregular';
    src: url('../fonts/saira/saira-regular-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairasemibold';
    src: url('../fonts/saira/saira-semibold-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairasemibold_italic';
    src: url('../fonts/saira/saira-semibolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_extracondensedsemibold';
    src: url('../fonts/saira/sairaextracondensed-semibold-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairaextracondensed-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_extracondensedSBdIt';
    src: url('../fonts/saira/sairaextracondensed-semibolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairaextracondensed-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_extracondensedthin';
    src: url('../fonts/saira/sairaextracondensed-thin-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairaextracondensed-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_extracondensedThIt';
    src: url('../fonts/saira/sairaextracondensed-thinitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairaextracondensed-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairaextralight';
    src: url('../fonts/saira/saira-extralight-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairaextralight_italic';
    src: url('../fonts/saira/saira-extralightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condenseditalic';
    src: url('../fonts/saira/sairacondensed-italic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedlight';
    src: url('../fonts/saira/sairacondensed-light-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedlight_italic';
    src: url('../fonts/saira/sairacondensed-lightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedmedium';
    src: url('../fonts/saira/sairacondensed-medium-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedmedium_italic';
    src: url('../fonts/saira/sairacondensed-mediumitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedregular';
    src: url('../fonts/saira/sairacondensed-regular-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedbold';
    src: url('../fonts/saira/sairacondensed-bold-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedbold_italic';
    src: url('../fonts/saira/sairacondensed-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedextrabold';
    src: url('../fonts/saira/sairacondensed-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedXBdIt';
    src: url('../fonts/saira/sairacondensed-extrabolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedextralight';
    src: url('../fonts/saira/sairacondensed-extralight-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedXLtIt';
    src: url('../fonts/saira/sairacondensed-extralightitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'sairablack';
    src: url('../fonts/saira/saira-black-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairablack_italic';
    src: url('../fonts/saira/saira-blackitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairabold';
    src: url('../fonts/saira/saira-bold-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sairabold_italic';
    src: url('../fonts/saira/saira-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/saira-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedblack';
    src: url('../fonts/saira/sairacondensed-black-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'saira_condensedblack_italic';
    src: url('../fonts/saira/sairacondensed-blackitalic-webfont.woff2') format('woff2'),
    url('../fonts/saira/sairacondensed-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}