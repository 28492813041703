/*
.datepicker{
  display: flex;
  flex-wrap: wrap;
  margin:0 0 10px 0;

  label:first-child{
    width: 100%;
  }

  select{
    margin:0 10px 0 0;
  }

  .datepicker-widget,
  .birthdaypicker-widget,
  .js-datepicker{
    display:none;
    position: absolute;
    z-index: 2;
  }

  .datepicker-widget.visible,
  .birthdaypicker-widget.visible,
  .js-datepicker.visible{
    display:block;
  }

  i{
    font-size:2rem;
    cursor:pointer;
  }
}
*/
