/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Color/Title/Font/link/List
2.0 Navigation
3.0 Header
4.0 Footer
5.0 Button
6.0 Footer
7.0 Pages
	 7.1 HOME
	 7.2 Family
	    7.2.1 Family list
	    7.2.3 Family details
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Color/Title/Font/link/List
--------------------------------------------------------------*/



@media (max-width: 991px){

    /*-----------------------------------------------------------------------------------------------------------------

    ------------------- 1.0 Color/Title/Font/link/List

    ----------------------------------------------------------------------------------------------------------------- */
    #modal-outils-home{
        margin: 0 0;
        z-index: 999;
        background: #fff;
        position: absolute;
        text-align: center!important;
        border: solid 1px #999;
        padding: 10px 0;
        max-width: 720px;
    }
    #modal-outils-home img{
        width: 50%!important;
    }
    #modal-outils-home svg{
        font-size: 50px
    }
    #modal-outils-home>div>div>div{
        height: 100px;
        padding: 0;
    }
    #modal-outils-home #statut-page{
        /*! margin-top: 10px!important; */
    }
    #modal-outils-home #identification{
        width: 40%!important;
        margin: 0 20px!important;
    }
    #modal-outils-home .hide {
        font-size: 50px;
    }
    #modal-outils-home #activation{
        margin-bottom: 20px;
        height: initial
    }
    #member-template #modal-outils-home{
        padding: 10px;
    }
    #modal-outils-home .content{
        padding-right: 20px;
    }
    /*--------------------------------------MENU--------------------------------------*/

    #new_menu{
        margin-bottom: 50px;
    }
    #new_menu .navbar{
        padding: 0.5rem 1rem;
    }

    #new_menu .nav-link svg{
        width: auto;
        display: inherit;
        margin: initial;
        margin-bottom: 0
    }
    #new_menu .nav-link {
        margin-bottom: 10px
    }

    /*-----------------------------------------------------------------------------------------------------------------

    ------------------- MEMBRES

    ----------------------------------------------------------------------------------------------------------------- */

    .bouton-lat{
        top: 90px!important;
    }
    #member-template h1{
        font-size: 18px
    }
    #member-template sup{
        top: 0;
        font-size: 12px;
    }
    .block-adresse{
        font-size: 12px;
    }
    #show, #edit {
        font-size: 20px
    }
    /*---------------------------------DETAILS MEMBRE---------------------------------*/
    #action-alerte .emails{
        color: #17a2b8 ;
    }
    #action-alerte .emails:hover{
        color: #fff;
    }
    #action-alerte .hover{
        top: -90px;
        left: 0;
        width: 100%;
        display: none;
    }
    #action-alerte .hover a{
        border-bottom: 1px solid #999!important;
        padding: 10px
    }
    #action-alerte .hover a:hover{
        background: #17a2b8;
        color: #fff;
    }
    #action-alerte .btn-group:hover .hover{
        display: block;
        position: absolute;
        bottom: 40px;
    }


    /*----------------------------------MEMBRE LISTE----------------------------------*/
    #member-section h1{
        text-align: center!important;
    }



    /*-----------------------------------------------------------------------------------------------------------------

    ------------------- ANIMAL

    ----------------------------------------------------------------------------------------------------------------- */
    .tuile-animal #show, .tuile-animal #edit{
        font-size: 20px;
        margin: 0 10px;
    }

    /*-----------------------------------------------------------------------------------------------------------------

    ------------------- tableaux

    ----------------------------------------------------------------------------------------------------------------- */
    #table_alerte_wrapper{
        overflow: scroll
    }


    .emails .btn-group{
        width: 100%;
    }


    /*-----------------------------------------------------------------------------------------------------------------

    ------------------- Présence

    ----------------------------------------------------------------------------------------------------------------- */
    .fc-left{
        width: 100%;
        margin-bottom: 10px;
    }
    .fc-left div{
        width: 100%;
    }

    .fc-left div button:nth-child(3){
        width: 65%
    }

    .fc-right{
        width: 100%;
        margin-bottom: 10px;
    }

    .fc-right .fc-button-group{
        width: 96%
    }

    .fc-right button{
        width: 33%;
        margin-bottom: 10px;
    }

    .fc-center h2{
        text-transform: capitalize;
    }
}