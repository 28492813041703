#animal-template{
  #global-animal, #detail-animal {
    i {
      margin-right: 10px !important;
    }
  }
  .identity {
    display: flex;
    align-items: center;
    img{
      width: 15px;
      margin-right: 9px;
    }
  }
}