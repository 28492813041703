
#identification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;

  .container {
    box-shadow: none;
    border: none;

    .psswrd .form-group,
    #identification .psswrd input {
      margin-bottom: 0 !important;
    }

    .btn-mdp {
      text-align: left;
      display: block;
      margin: 0
    }

    .remember_me {
      label {
        font-size: 80% !important;
        display: flex;
        justify-content: flex-end;
      }

      input[type="checkbox"] {
        //height: 0 !import;
        margin-right: 5px;
        height: 13px;
        width: 13px;
      }

      .toggle {
        min-height: 18px !important;
        min-width: 20px;
      }

      .toggle-handle {
        vertical-align: top;
        padding: 0 5px
      }
    }

  }
}

#new_organisme, #form_absolute {

  .rgpd {
    label {
      font-size: 14px;
      display: flex;
      align-items: center;
      //justify-content: flex-end
    }
  }
}