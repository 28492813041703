input.updated{
  background-color: $updated;
}

.button-hover-input-wrapper{
  position: relative;

  .hover-input{
    position: absolute;
    right: 0;
    top:0;
    height:100%;
    border-radius: 0 0.25rem 0.25rem 0;
    display: inline-block;
    background: #ccc;
    padding: 5px 10px;
    cursor: pointer;

    i{
      vertical-align: middle;
    }
  }
}

/*//modif bouton upload*/

#FakeInput {
  position: relative;
  overflow: hidden;
}

#FakeInput input {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

#FakeInput  span {
  display: inline-block;
  vertical-align: top;
}

#FakeInput span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  height: 25px;
  padding-left: 5px;
  border: 1px solid #ccc;
  text-overflow: ellipsis;
  font: 100%/25px sans-serif;
  color: #666;
  border-radius: 3px;
}

#FakeInput span + span {
  margin-left: 5px;
  text-align: center;
  //color: #1F9EE5;
  text-decoration: underline;
}
#FakeInput label{
  display: initial;
}
#project-container #FakeInput label{
  display: none;
}