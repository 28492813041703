@import "../variables";


.moutarde{
    color: #ffc107!important;
}
.vert{
    color: #28a745!important;
}
.vert.btn:hover{
    background: #28a745!important;
    color: white!important;
}
.red{
    color: #c82333!important;
}
.pourpre{
    color: #8b0000!important;
}
.brown{
    color: #93282a!important;
}
.blue{
    color: #4169E1!important;
}
.blue.btn:hover{
    background: #4169E1;
    color: white!important;
}

.blue_light{
    color: #97bfe3!important;
}
.grey{
    color: #bfbfbf!important;
}
.orange{
    color: #D2691E!important;
}
.orange_dpm{
    color: $course_color;
}
.orange_dpm.btn:hover{
    background: $course_color!important;
    color: white!important;
}
.blue_dpm{
    color: $dpm_color!important;
}
.blue_dpm.btn:hover{
    background: $dpm_color!important;
    color: white!important;
}
.red_strong{
    color: #D80A0A!important;
}
.yellow{
    color: #eec64d!important;
}
.green_dpm{
    color: #3cb371!important;
}
.green_dpm.btn:hover{
    background: #3cb371!important;
    color: white!important;
}
.purple_dpm{
    color: $logistic_color!important;
}
.purple_dpm.btn:hover{
    background: $logistic_color!important;
    color: white!important;
}
.blue_reload{
    color: #17a2b8!important;
}