
#menu-sidebar.project_menu {
  .hc-offcanvas-nav {
    a {
      border-bottom: 1px solid $logistic_color;
    }
    .nav-item {
      border-bottom: 1px solid $logistic_color;
    }
  }
}

#project-container {
  #form_absolute.creation_form,
  #form_absolute.creation_form_doc,
  #form_absolute_report.creation_form_report{
    .content {
      border: solid $logistic_color;
    }
  }
  #form_absolute_button{
    right: 20px;
  }
  .tab {
    h2 {
      &::after {
        background: none repeat scroll 0 0 $logistic_color;
        height: 3px;
      }
    }
  }
  >.container, .container.project {
    border: solid $logistic_color 1px !important;
    position: relative;
    max-width: unset;
    //min-height: 100vh;
  }
  .home_header {
    border-bottom: solid 1px;
    padding-bottom: 10px;
    >div{
      @include media-breakpoint-down(md) {
        margin: 5px 5px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        width: 21%;
        justify-content: center;
        text-align: center;
        padding: 5px;
      }
    }
    .home_header{
    }
  }
  .tab_content {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    border-left: solid 3px $logistic_color;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    width: 32%;
    margin-right: 1.3%;
    padding: 10px;
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0%;
    }
    >.top {
      display: flex;
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid $logistic_color;
      >div {
        width: 50%;
      }
    }
    .top {
      .right {
        display: flex;
        justify-content: flex-end;
        div {
          margin-left: 10px;
        }
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .top {
        margin-top: 10px;
        font-weight: bold;
        font-size: 1.2rem;
        width: 100%;
        margin-bottom: 10px;
      }
      .right {
        width: 100%;
        padding-bottom: 5px;
        padding-top: 5px;
      }
      .left {
        border: solid 1px $logistic_color;
        width: 48%;
        padding: 5px;
        .text-center {
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
      .middle {
        border: solid 1px $logistic_color;
        width: 48%;
        padding: 5px;
        .text-center {
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
      ul {
        width: 100%;
        li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          width: 100%;
        }
      }
    }
  }
  .add_form_doc_button{
    right: 160px!important;;
  }


  #project_details {
    #project_quick_action {
      .delete_report {
        margin-left: 15px;
      }
    }

    .infos {
      display: flex;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        justify-content: center;
      }
      >div {
        margin: 0 5px;
        display: flex;
        align-items: center;
        border: solid 1px;
        border-radius: 10px;
        width: 22%;
        justify-content: center;
        text-align: center;
        padding: 5px;
        @include media-breakpoint-down(md) {
          margin-bottom: 5px;
          width: 30%;
        }
      }
    }
    section {
      border-bottom: solid 1px $logistic_color;
      padding: 20px 0 10px;
    }
    .description {
      .card {
        width: 31%;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
          width: 45%;
        }

        .bottom {
          margin: 0;
        }
      }

    }
    .legende {
      position: absolute;
      top: 25px;
      left: 50%;
      @include media-breakpoint-down(md) {
        left: unset;
        right: 0;
      }
      .content {
        position: absolute;
        right: 50%;
        width: 250px;
        >div {
          position: relative;
          padding-left: 12px;
          font-size: 12px;
          &:before {
            content: '';
            display: block;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        display: none;
      }
      .dead {
        &:before {
          background: red;
        }
      }
      .encours {
        &:before {
          background: grey;
        }
      }
      .passed {
        &:before {
          background: green;
        }
      }
      .proche {
        &:before {
          background: orange;
        }
      }
      .help_notice {
        border: 1px $logistic_color solid;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $logistic_color;
        color: white;
        cursor: default;
        padding: 0;
      }
      &:hover {
        .content {
          display: block !important;
          border: solid 1px;
          padding: 10px;
          background: white;
          z-index: 99;
        }
      }
    }
  }
  #tasks, #calendar1{
    height: 100%;
  }



}