.essai {
  color: #ffc107
}

.valid {
  color: #28a745
}

.readh {
  color: #c82333
}

.cxl {
  color: #8b0000;
}

@media screen and (max-width: 767px) {
  #navbar .navbar-nav li iframe {
    display: none;
  }
  #navbarSupportedContent li a svg {
    width: 40px !important;
    margin: 0 !important;
    margin-right: 10px !important;
  }
  #navbarSupportedContent li a {
    display: flex !important;
  }
}

.container {
  background: #fff;
  padding-top: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px 2px #999;
}

#stat.container {
  border: solid #eec64d !important
}

.resume-text,
#comment,
.detail-text,
.tuile-animal,
#animal-problems,
#section #tuiles .ok,
#member-section .members-list,
#accueil-creation .vignette {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: 2px !important;
  box-shadow: 1px 0 3px #999 !important;
  padding: 10px !important;
  border-left-width: 3px !important;
  //border-left:  solid $dpm_color;
}

.descriptif {
  margin-top: 20px;
}

.er-contact {
  height: 81px;
}

#animal-zone, #member-zone {
  margin-top: 30px;
}

.tuile-animal {
  padding: 10px;
  margin-bottom: 10px;
}

.tablinks {
  cursor: pointer
}

#very-small {
  font-size: 10px;
}

#small {
  font-size: 12px;
}

#retour {
  height: 100% !important;
}

#doc-pdf, #doc-word {
  font-size: 120px;
}

#titre {
  color: $dpm_color;
  font-weight: bold;
}

.resume-text {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.tuile-animal {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.detail-text {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px;
}

.assurance {
  color: #c82333;
}

.attention {
  color: #FF8C00
}

.block-adresse {
  font-size: 17px;
  padding: 0;
}

#animal .assurance, #animal .proches, #animal .attention {
  font-weight: normal !important;
}

#animal .assurance i, #animal .proches i, #animal .attention i {
  font-weight: 900 !important;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}


#bold {
  font-weight: bold;
}

form li {
  color: red;
  font-weight: bold;
}

/******** Form***************/
.container {
  border: solid $dpm_color;
  border-radius: 10px;
}

.container .top {
  margin-top: 50px
}

.container .bottom {
  margin-bottom: 20px;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #495057;
}

.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #495057;
}

.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #495057;
}

.form-control:-moz-placeholder { /* Firefox 18- */
  color: #495057;
}

label {
  display: block;
  font-size: 20px;
}

input {
  //height: 25px;
  width: 100%;
}

textarea {
  height: 150px;
  padding: 10px;
  width: 100%;
}

select {
  width: 100%;
  padding: 0;
}

#animal_solo_images_file, #app_bundle_animal_type_images_file {
  height: 46px !important;
}


#member_solo_idfamily {
  width: auto;
}

h2 {
  font-size: 30px;
  font-weight: normal
}

#bouton-row {
  margin-top: 30px;
}

.container {
  padding-top: 10px;
}

hr {
  margin-bottom: 4px;
}

#app_bundle_family_type_id_type {
  width: 100%
}

.adherent #app_bundle_adherent_type_image_rights,
.adherent #app_bundle_adherent_type_job,
.adherent #app_bundle_adherent_type_categories,
#member_solo_idfamily {
  width: 100% !important;
}

.animal select {
  width: 100%
}

.animal #app_bundle_animal_type_sterilized, #animal_solo_sterilized {
  /*   border: solid; */
  display: inline-flex

}

.animal #app_bundle_animal_type_sterilized label,
#animal_solo_sterilized label,
.animal #app_bundle_animal_type_sterilized #app_bundle_animal_type_sterilized_0,
.animal #app_bundle_animal_type_sterilized #app_bundle_animal_type_sterilized_1,
#animal_solo_sterilized_0, #animal_solo_sterilized_1 {
  /*   border: red solid; */
  width: 50%;
  display: block;
}

#app_bundle_animal_type_sterilized_0, #app_bundle_animal_type_sterilized_1,
#animal_solo_sterilized_0, #animal_solo_sterilized_1 {
  margin: 0 10px 0 20px;
}

.animal #app_bundle_animal_type_other_vaccins {
  height: 104px;
}

.animal #image-holder img {
  width: 100%;
  border: solid white 1px;
}

.animal #image-holder {
  max-height: 200px;
  overflow: auto;
}

#app_bundle_family_type_familyStatus, #app_bundle_family_type_id_type {
  width: 100%;
  text-transform: capitalize;
}

.contact textarea {
  height: 70px !important;
  margin-bottom: 15px;
}

.commentaire textarea {
  height: 100px !important;
}

.thumb-image {
  width: 100%;
  padding: 10px 0;
}

#member_solo_save_and_add, #animal_solo_save_and_add {
  display: none;
}


#app_bundle_adherent_type_idfamily {
  border: none;
}

.big select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*******************FOND FORM FAMILY***********************/
.family {
  position: relative;
}

//.family:before {
//  /*     cotent: "\f0c0"; */
//  content: "\F0C0";
//  color: $dpm_color;
//  color: rgba(89, 189, 189, .1) !important;
//  font-family: 'Font Awesome 5 Free';
//  font-weight: 900;
//  font-size: 500px;
//  position: absolute;
//  bottom: 0;
//  left: 50%;
//  margin-left: -312px;
//}

@media (max-width: 665px) {

  .family:before, .adherent:before {
    display: none;
  }
}

/*******************FOND FORM adherent***********************/

.adherent {
  position: relative;
}

#app_bundle_try_adherent_type_idfamily {
  border: none
}

/*******************FOND FORM adherent try***********************/

.adherent-try {
  position: relative;
}

.adherent-try:before {
  content: "\F007";
  color: $dpm_color;
  color: rgba(89, 189, 189, .1) !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -312px;
}

/*******************FOND FORM ANIMAL***********************/

.animal {
  position: relative;
}

.death label {
  font-size: 12px !important;
}

/*********try*******************/
.animal-try {
  position: relative;
}

.animal-try:before {
  content: "\F1B0";
  color: $dpm_color;
  color: rgba(89, 189, 189, .1) !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -312px;
}

/*******************FOND FORM FAMILY TRY***********************/
.family-try {
  position: relative;
}

.family-try:before {
  content: "\F0C0";
  color: $dpm_color;
  color: rgba(89, 189, 189, .1) !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -312px;
}

/********************* section************/
.search .search-filtre input {
  font-size: 20px;
}

.search .alert-filtre button {
  font-size: 20px;
}

.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.search > div:first-child {
  width: 100% !important;
  margin-bottom: 10px;

}

.search > div:first-child .input-flat {
  margin: 0 !important;
}

.search > div:first-child .form-group {
  margin: 0
}

#section #tuiles {
  padding: 10px;
}

#section a {
  color: initial
}

#section #tuiles h3 {
  font-size: 20px;
}

#members-section #tuiles a {
  color:$txt_color_dark
}

#members-section #tuiles a:hover {
  text-decoration: none;
}

#section .icones, #section .rages {
  //position: absolute;
  width: 100%;
  margin: 0;
  bottom: 5%;
  font-size: 25px;
}

#section #tuiles .ok {
  position: relative;
}

//#section #family #tuiles .ok:before {
//  /*     cotent: "\f0c0"; */
//  content: "\F0C0";
//  color: $dpm_color;
//  color: rgba(89, 189, 189, .1) !important;
//  font-family: 'Font Awesome 5 Free';
//  font-weight: 900;
//  font-size: 80px;
//  position: absolute;
//  top: 10px;
//  left: 50%;
//  margin-left: -50px;
//}

#section .valide {
  color: #28a745;
}

#section .affiche {
  position: relative;
  color:$txt_color_dark;
}

#section a {
  text-decoration: none;
}

#section .card-body {
  position: relative;
  //height: 100px;
  padding: 0;
  /*     padding-top: 0 */
}

#section .assurances:hover .hover, #section .adhesion:hover .hover, #section .status:hover .hover, #section .rages:hover .hover, #section .id-family:hover .hover {
  display: block;
}

#section .assurances .hover, #section .adhesion .hover, #section .status .hover, #section .rages .hover, #section .id-family .hover {
  border: solid 1px $dpm_color;
  display: none;
  background: #FFFFFF;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#section .hover h3, #section .hover h3 {
  font-size: 13px !important;
}

#section .text-hover {
  font-size: 11px;
}

#section #family-number {
  font-size: 20px;
}

#section .sous-titre {
  font-size: 13px;
}


#section {
  #animal {
    .details {
      display: flex;
      justify-content: space-between;
      text-align: left;
      flex-wrap: wrap;
    }
    .details>div {
      display: flex;
      align-items: center;
      width: calc(100% / 6);
      @include media-breakpoint-down(md) {
        margin-top: 10px;
        width: calc(100% / 2);
      }
    }
  }
}

#section #animal .details > div > div {
  width: 100%
}

#section #animal .details h3 {
  margin: 0;
  padding: 0;
}


/*!****************************general icons*************************************!*/
#perfect {
  color: #28a745;
}

#wrong {
  color: #dc3545;
}

#birth, #phone, #animal-template #user, #email {
  color: #4169E1;
}

#show, .show {
  color: #bfbfbf;
}

#edit {
  color: #D2691E;
}

#femelle, #male, #passeport, #identity, #categorie, #student, #activity, #job-case, #user-add, #user-link {
  color: #6E6e6e;
  margin-right: 5px;
}

#paw, #family {
  color: $dpm_color;
}

#no-docs, #steri-no {
  color: #D80A0A;
}

#yes-docs, #steri-yes, #valid {
  color: #28a745;
}

#post-it {
  color: #DAA520;
}

#try {
  color: #ffc107;
}

#redo {
  color: #c82333;
}

#soon, .soon {
  color: #FF8C00;
}

#delete, #canceled {
  color: #8B0000;
  font-size: 40px;
  padding-top: 10px;
}

#alerte {
  color: #c82333;
}

#activite #delete, #race #delete {
  font-size: 15px;
}

#statut-page {
  width: 20px;
  margin: 0 7px;
}

.fa-syringe {
  margin-right: 0 !important;
}

/**********************CREATION PAGE*********/

#accueil-creation .vignette {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 5px $dpm_color;
  border-radius: 10px;
  font-size: 30px;
  text-decoration: none;
  height: 150px;
  width: 100%;
  padding: 20px 10px;
  text-align: center;
}

#accueil-creation .vignette:hover {
  background: $dpm_color;
  color: white;
}

/********DOCS PAGE********/
/*******animal template*********/
#detail-animal ul li {
  width: auto;
  display: inline-flex
}

#animal-template .liste-conducteur {
  padding: 0;
}

#animal-template .descriptif #detail-animal {
  position: relative
}

#comment {
  position: relative;
}


#animal-template .descriptif #global-animal {
  position: relative
}

#animal-template #school {
  position: relative
}

#animal-template #school #show {
  width: 15px
}

#animal-template #global-animal .row, #animal-template #detail-animal .col-12 {
  margin-bottom: 5px;
}

#animal-template ul {
  padding-left: 0;
}

#animal-template #school div {
  /*   border: solid; */
  padding-left: 15px;
}

#animal-template .liste-conducteur li {
  /*   border: solid; */
  list-style: none;
  width: 48%;
  display: inline-table
}

#animal-template #passeport {
  width: 12px;
  margin-right: 8px;
  margin-left: 5px;
}

#animal-template .descriptif svg {
  width: 25px;
}

#animal-template .liste-conducteur a {
  padding-right: 5px;
}

#animal-template .descriptif .resume-text, #member-template .descriptif .resume-text {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

#animal-template .resume-text #myImg {
  max-height: 270px;
}

/* Style the Image Used to Trigger the Modal */
#myImg {
  /*border-radius: 5px;*/
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  box-shadow: 3px 3px 3px #999;
}

#myImg:hover {
  opacity: 0.7;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  box-shadow: none;
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  /*color: #ccc;*/
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 7%;
  right: 21%;
  color: #ffffff !important;
  font-size: 70px;
  font-weight: bold;
  transition: 0.3s;
}


.close:hover,
.close:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}


/**************************member template ***************************/
#member-template li {
  list-style: none;
}

#member-template ul {
  padding: 0
}

#member-template svg {
  margin-right: 10px;
}

#member-template #small {
  font-size: 18px;
}

.member-category li {
  /*   border: solid; */
  list-style: none;
  width: 49%;
  display: inline-table
}

#member-template #global-details .resume-text {
  position: relative;
}

#member-template #category-details .resume-text {
  position: relative;
}

/**************************member list ***************************/

#member-section .members-list {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px 0;
  font-size: 14px;
  /*   color: white; */
}

#member-section #show, #member-section #edit {
  font-size: 20px;
}

/***************date form******************/

#app_bundle_animal_type_rag_date_month,
#app_bundle_animal_type_rag_date_day,
#app_bundle_animal_type_rag_date_year,
#family_solo_first_adh_date_month,
#family_solo_first_adh_date_day,
#family_solo_first_adh_date_year,
#member_solo_dob_month,
#member_solo_dob_day,
#member_solo_dob_year,
#family_solo_end_adh_date_month,
#family_solo_end_adh_date_day,
#family_solo_end_adh_date_year,
#family_solo_insuranceDate_month,
#family_solo_insuranceDate_day,
#family_solo_insuranceDate_year,
#app_bundle_animal_type_dob_day,
#app_bundle_animal_type_dob_month,
#app_bundle_animal_type_dob_year,
#app_bundle_adherent_type_dob_day,
#app_bundle_adherent_type_dob_month,
#app_bundle_adherent_type_dob_year,
#app_bundle_family_type_end_adh_date_day,
#app_bundle_family_type_end_adh_date_month,
#app_bundle_family_type_end_adh_date_year,
#app_bundle_family_type_first_adh_date_day,
#app_bundle_family_type_first_adh_date_month,
#app_bundle_family_type_first_adh_date_year,
#app_bundle_family_type_insuranceDate_day,
#app_bundle_family_type_insuranceDate_month,
#app_bundle_family_type_insuranceDate_year,
#animal_solo_dob_day,
#animal_solo_dob_month,
#animal_solo_dob_year,
#animal_solo_rag_date_day,
#animal_solo_rag_date_month,
#animal_solo_rag_date_year,
#animal_solo_dod_day,
#animal_solo_dod_month,
#animal_solo_dod_year,
#app_bundle_animal_type_dod_day,
#app_bundle_animal_type_dod_month,
#app_bundle_animal_type_dod_year,
#app_bundle_animal_type_dateOtherVaccin_day,
#app_bundle_animal_type_dateOtherVaccin_month,
#app_bundle_animal_type_dateOtherVaccin_year,
#animal_solo_dateOtherVaccin_day,
#animal_solo_dateOtherVaccin_month,
#animal_solo_dateOtherVaccin_year,
#app_bundle_alerte_type_alerteDate_day,
#app_bundle_alerte_type_alerteDate_month,
#app_bundle_alerte_type_alerteDate_year {
  width: 32.2% !important;
}

/*********************NAV DROITE**************************/

.bouton-lat {
  display: inline-block;
  position: fixed;
  right: 0;
  top: 110px;
  z-index: 100;
  background: #f8f9fa;
  box-shadow: 0 0 5px #999;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.bouton-lat svg {
  font-size: 30px;
  color: #008080;
  margin: 10px 0;
  background: #f8f9fa;
}

.bouton-lat .btn, .button {
  border: none;
  background-color: #f8f9fa;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: auto;
}

.bouton-lat .sidebar {
  width: 0;
  z-index: 100;
  margin-left: 10px;
}

/********************INDEX ******************/
//#index {
//  h1 {
//    svg {
//      margin-right: 10px;
//    }
//  }
//
//  h5 {
//    margin-bottom: 30px;
//    font-size: 30px;
//  }
//
//  .stats-membres {
//    h1 {
//      color: $dpm_color
//    }
//  }
//
//  th, td {
//    border-bottom: 1px solid #ddd;
//  }
//
//  .fa-cloudscale {
//    font-size: 80px;
//    color: #d4d4d4;
//  }
//
//  a {
//    text-decoration: none;
//
//    h2 {
//      font-size: 2rem;
//    }
//  }
//
//  .compte_home {
//    display: flex;
//    justify-content: space-evenly;
//    padding: 0 15px;
//    flex-wrap: wrap
//  }
//
//  .compte_home > div {
//    width: 33%;
//    padding: 0 10px;
//  }
//
//  .compte_home > div:nth-child(2) {
//    border-left: 1px solid $dpm_color;
//    border-right: 1px solid $dpm_color;
//
//  }
//}

/****************ACCEUIL*******************/
//#accueil-adherent {
//  margin-top: 5%;
//}
//
//#accueil-adherent .col-4 a {
//  background: $dpm_color;
//  border-radius: 10px;
//  padding: 20px;
//  text-align: center;
//  color: #ffffff;
//}
//
//#accueil-adherent .col-4 a:hover {
//  border: 1px $dpm_color solid;
//  background: none !important;
//  color: #59DBDB;
//  font-weight: bold
//}

/******************DOCUMENTS***************/
#documents img {
  width: 100%;
  padding: 10px 0;
}

#app_bundle_documents_imageFile, #add_document_solo_imageFile {
  padding: 0 !important;
}

#detail-doc {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px;
  position: relative
}

#documents td, td {
  position: relative;
}

/******************COMMENT ZONE************/
#comment {
  border: solid 5px $dpm_color;
  border-radius: 10px;
  padding: 10px 0;
  height: 100%;
}

#member-template #comment .description, #animal-template #comment .description {
  /*border: solid 1px $dpm_color;*/
  height: 190px;
  overflow: auto;
  border-radius: 5px;
  padding: 5px 0;
  word-wrap: break-word;
}

#comment .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #495057;
}

#comment .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #495057;
}

#comment .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #495057;
}

#comment .form-control:-moz-placeholder { /* Firefox 18- */
  color: #495057;
}

#comment textarea {
  height: 100px;
  padding: 10px;
  width: 100%;
}

/*****************************config*******************************************/
#config-wrapper .container {
  border-radius: 10px;
}

#config-wrapper select, #config-wrapper input, #config-wrapper label {
  display: initial;
}

#config-wrapper #activite .search label, #config-wrapper #race .search label {
  display: block !important;
  margin: 20px auto;
}

#power-on {
  color: #c82333;
  font-size: 25px
}

#user_activity_table #userRole #edit {
  float: left;
  font-size: 25px
}

input[type="radio"] {
  -webkit-appearance: radio !important;
  border-radius: 0;
}

input, textarea, button {
  /*-webkit-appearance: none;*/
  -webkit-font-smoothing: antialiased;
  resize: none;
}


input[type="checkbox"] {
  background-color: #eee;
  width: 20px;
  height: 20px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.input-flat {
  width: 100%;
}

.cRetour {
  border-radius: 3px;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  z-index: 99999;
  transition: all ease-in 0.2s;
  position: fixed;
  cursor: pointer;
  bottom: 1em;
  right: 20px;
  display: none;
}

.cRetour:hover {
  background: rgba(0, 0, 0, 1);
  transition: all ease-in 0.2s;
}

#arrow, #phone {
  font-size: 15px;
}

#config-wrapper ul {
  width: 100%;
}

//#config-wrapper ul li {
//  width: 49%;
//  display: inline-block;
//}

/*******************USER ROLE********************/

#power-off {
  color: #c82333;
  font-size: 25px
}

#warning {
  color: #FFA500;
  font-size: 25px;
}

#power-on {
  color: #28a745;
  font-size: 25px
}

#userRole #edit {
  float: left;
  font-size: 25px
}

#userRole {
  margin-bottom: 50px;
}

/*******************EXPORTS***********************/
#export-choice a:not(.link-courses), #export-choice .btn-secondary {
  border-radius: 2px !important;
  box-shadow: 3px 0 3px #999 !important;
  border-left: 3px solid #eec64d !important;
  padding: 10px;
  color: #565656;
  text-align: center !important;
  font-size: 30px;
  background: #fff;
  width: 100%;
}

#export-choice {
  padding: 20px
}

#export-choice a svg {
  font-size: 30px;
  color: #eec64d;
}

#export-choice a:hover svg, #export-choice .active svg {
  color: #565656
}

/******************ALERTE**********************/
#alert-wrapper .container {
  border: solid #c82333 3px;
  border-radius: 10px;
  margin: 50px auto 20px;
  padding-top: 10px;
}
//
//#alert-wrapper label {
//  display: block;
//}
//
//
//#alert-wrapper #app_bundle_alerte_type_alerteDate_day,
//#alert-wrapper #app_bundle_alerte_type_alerteDate_month,
//#alert-wrapper #app_bundle_alerte_type_alerteDate_year {
//  width: 32.9%;
//}
//
//#alert-wrapper textarea {
//  width: 100%;
//}
//
//#liste-alerte tbody th, #cotisation-alerte tbody th {
//  font-weight: normal;
//}
//
//#liste-alerte .filtre {
//  display: flex;
//  justify-content: space-evenly;
//  flex-wrap: wrap
//}
//
//#liste-alerte .filtre > button {
//  margin-top: 10px;
//}
//
//#action-alerte .hover {
//  display: none;
//}
//
//#action-alerte .hover a {
//  margin: 0 !important;
//  width: 100% !important;
//  border: none !important;
//  color: #17a2b8;
//}
//
//#action-alerte .hover a:hover {
//  background: #17a2b8;
//  color: #fff;
//}


/***********************************************************************************************************
modals
 */

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /*$txt_color_dark w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

/* The Close Button */
.closeModal {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeModal:hover,
.closeModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* page saisi commentaire*/

#comment-zone textarea {
  width: 100%;
}


/*************OUTILS******************************/

/*************OUTILS******************************/
#outils .on-off {
  padding: 0 10px;
}

#outils .on-off a {
  line-height: 2
}

#outils {
  position: relative;
}

#outils #identification {
  width: 40%;
  display: block;
  margin-left: auto;
}

#outils #over-outils {
  width: 100%;
  position: absolute;
  top: 55%;
  right: 0;
}

#outils #identification:hover #over-outils ul {
  display: block;
}

#outils #over-outils ul {
  list-style: none;
  margin: auto;
  width: 80%;
  box-shadow: 0 0 5px #999;
  background: #fff;
  z-index: 900;
  display: none;
}

#outils #over-outils li {
  width: 33%;
  float: left;
  text-align: center !important;
}

#outils #over-outils label {
  display: block;
  text-align: center;
}

#outils #over-outils .id-number, #outils #over-outils .on-off {
  width: 100%;
}

#outils #over-outils li img {
  width: 50%;
  display: block;
  margin: 10px auto;
}

#outils #over-outils li svg {
  font-size: 30px;
  text-align: center !important;
}

#outils #over-outils li a {
  display: block;
  text-align: center;
  width: 100%;
  margin: 0 0;
  padding: 0;
  height: 40px;
}

#family-desc #outils .family-suppresion:hover .suppresion-hover {
  display: flex;
}

#family-desc #outils .family-suppresion .suppresion-hover {
  display: none;
}

#family-desc #outils .family-suppresion .suppresion-hover a {
  line-height: 2.2;
  margin-top: 5px;
}

#family-desc #outils .family-suppresion .suppresion-hover a:hover {
  line-height: 2.2;
  color: #fff;
}

/***********************************************************************/
/*    email */
/***************************/
#create-email input, #create-email textarea, #create-email select {
  width: 100%
}

#email-wrapper .container {
  border: solid #97bfe3 3px;
  border-radius: 10px;
  margin: 50px auto 20px;
  padding-top: 10px;
}

#email-send select, #email-send textarea {
  width: 100% !important;
}

#email-send input {
  width: 100%
}

ul {
  list-style: none;
  padding: 0;
}

/***********************************************************************/
/*    formulaires */
/***************************/

.comment_form {
  border: none;
  background: transparent;
  width: 100% !important;
  resize: none;
}

#form_required input {
  width: 100%;
}

.preview_front_box {
  box-shadow: 0 0 10px #999;
  background: rgba(0, 0, 0, .8);
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.preview_front_box .preview_box {
  background: #fff;
  display: block;
  width: 90vw;
  margin: auto;
}

.preview_front_box img {
  padding: 0;
  margin: 0;
}

#documents .container {
  position: relative;
}

#close_preview {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: white;
}

#close_preview:hover {
  background: #c82333;
  color: #fff;
}


div.dts div.dataTables_scrollBody {
  background: none
}

.dataTables_wrapper {
  overflow-x: auto;
}

.dataTables_wrapper input,
.dataTables_wrapper label,
.dataTables_wrapper select {
  width: initial;
  font-size: initial;
}

.dataTables_wrapper div.dt-buttons {
  float: right;
}

.dataTables_wrapper button.dt-button,
.dataTables_wrapper div.dt-button,
.dataTables_wrapper a.dt-button {
  margin-right: 0;
}

#table_alerte_filter {
  width: 100%;
}

#table_alerte_filter input {
  width: 74%;
}


/***********************************************************************/
/*    SEARCH PAGE */
/***************************/


#search_result a:hover {
  text-decoration: none;
  color: inherit;
}

/***********************************************************************/
/*    presences */
/***************************/

#course-wrapper .container {
  border: solid #f8aa6a;
}

#course-wrapper a:hover {
  text-decoration: none;
}

.liste_moniteurs li {
  list-style: none;
}

.fc-center h2 {
  text-transform: capitalize;
}

#course-wrapper .toggle-group label {
  margin: 0 !important;
}

#article_filter_mobile {
  position: fixed;
  left: 0;
  bottom: 1vh;
  z-index: 99999;
  width: 93%;
  margin: 0 2.75vw;
  background: #fff;
  border-radius: 20px;
  padding: 5px 5px 0;
  box-shadow: 0 3px 50px #0000001C;
}

#article_filter_mobile .panel-heading a {
  width: 93%;
}

#article_filter_mobile .panel-default {
  margin: 10px;
}

#article_filter_mobile .category_title {
  line-height: 3;
  font-weight: bold;
  position: relative;
  color: #a7a8a9;
}

#article_filter_mobile ul {
  list-style: none;
}

#article_filter_mobile .press_title {
  font-size: 16px;
  line-height: 3;
  color: #003b5c;
}

#article_filter_mobile .categorie_titre ul, #article_filter_mobile .sub_filtre {
  padding-left: 0;
  margin-top: 10px;
}

#article_filter_mobile {
  /*   border: solid;  */
  box-shadow: 0 0 10px #999;
  cursor: pointer;
}


.family-suppresion:hover {
  position: relative;
}


.family-suppresion:hover .rgpd-select {
  display: block !important;
  position: absolute;
  z-index: 9;
  background: white;
  padding: 10px;
  box-shadow: 0 0 1px #999
}

#export-views #table_responsive_wrapper {
  width: 100%;
}

#quick_add_form {
  position: fixed;
  top: 0;
  bottom: 0;
  border: solid;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.5);
  z-index: 9999999;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    i {

      color: black;
      font-size: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .content {
    background: white;
    width: 90vw;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include media-breakpoint-down(md) {
      top: 0;
      bottom: 0;
      transform: none;
      left: 0;
      right: 0;
      width: 100%;
      overflow: scroll
    }
    .title {
      text-align: center;
      margin-bottom: 30px;
      font-size: 25px;
    }
    .form {
      display: flex;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
      .select {
        width: 32%;
        margin: 1%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
    .submit {
      text-align: right;
      margin-top: 20px;
    }
  }
}
