#course-wrapper {
  .mandatory {
    border: solid 2px red;
    border-radius: 8px;
    position: relative;
    &:after {
      content: "Un de ces champs est obligatoire";
      position: absolute;
      z-index: 9;
      background: rgba(0, 0, 0, .8);
      padding: 2px 10px;
      display: block;
      width: 100%;
      color: white;
      top: 110%
    }
  }
}