#configuration-page.modules h2:after{
  content: '';
  display: block;
  width: 10%;
  height: 2px;
  background: #71b8ab;
  position: absolute;
  left: 45%;
  bottom: -10px;
}
#configuration-page.modules h2{
  position: relative;
  margin-bottom: 30px
}
#configuration-page.modules .vignette{
  padding: 30px;
  box-shadow: 0px 8px 20px -12px rgba(0, 0, 0, 0.75);;
  margin-bottom: 50px!important;
  min-height: 320px!important;
}
#configuration-page.modules>.container {
  padding: 30px
}
#configuration-page.modules .vignette .details{
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 10px;

  @include media-breakpoint-down(md) {
    position: static;
  }
}
#configuration-page.modules .image_block{
  text-align: center;
}
#configuration-page.modules .image_block img{
  width: 40px
}
#building{
  background: red;
  color: white;
  padding: 10px;
  width: 240px;
  position: absolute;
  left: -8%;
  top: 10%;
  transform: rotate(-35deg);
  text-align: center;
  @include media-breakpoint-down(md) {
    left: -17%;
    top: 7%;
  }
}
#configuration-page .vignette{
  overflow: hidden;
  position: relative;
}