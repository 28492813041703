/*********************************************
OVERRIDE DE HCOFFCANVAS
 */
.hc-offcanvas-nav .nav-close a:hover{
  color: #FFFFFF;
}


header{
  display: flex;
  height: 80px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 0;
  transition: all 200ms linear;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
}
header .logo{
  max-width: 100px;
  margin-left: 1%;
  @include media-breakpoint-down(md) {
    width: 15%;
    margin-left: 20px;
  }
}
header .logo img{
  max-width: 200px;
  max-height: 65px;
  height: 100%;
}

header #search-btn{
  margin-right: 1%;
  width: 5%;
  padding: 0 0 0 6px;

  @include media-breakpoint-down(md) {
    position: absolute;
    right: 50px;
    top: 25px;
  }

}
header #menu-site{
  width: 90%;
  justify-content: space-between;
}
header #menu-site>ul{
  width: 100%!important;
  display: flex;
  justify-content: space-evenly;
}
header #menu-site ul{
  list-style: none;
  padding: 0;
}
header #menu-site>ul>li{
  width: 11.1111111111%;
  position: relative;
}
#menu-site>ul>li>a{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
#menu-site>ul>li>a span{
  text-align: center;
  width: 100%;
  //margin-top: 8px;
}
.menu_link img{
  /*   border: solid; */
  width: 35px;
  margin: 0 auto;

}
#menu-site>ul>li>a i{
  margin: auto;
  width: 100%;
  text-align: center;
}
#menu-site ul li ul{
  position: absolute;
  padding: 10px;
  font-size: 13px;
  letter-spacing: 1px;
  background: #fcfaff;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  display: none;
}
#menu-site>ul>li i{
  font-size: 20px
}
#menu-site ul li:hover ul{
  display: block
}

#menu-site ul li ul li a{
  padding: 3px 15px;
  margin: 5px 0;
  border-radius: 2px;

}

#menu-site ul li ul li a:hover{
  margin: 5px 0;
  background: rgba(129,103,169,.6);
  width: 100%;
  color: #fff;
}
#menu-site ul li ul li a i{
  padding: 0;
  margin: 5px 0;
}
#menu-site a:hover{
  text-decoration: none!important;
  cursor: pointer;
}

#menu-site .sub-item .right-select:hover .right-submenu{
  display: block!important;
}
#menu-site .sub-item .right-submenu{
  position: absolute;
  left: 100%;
  top: 0;
  display: none;
}

#menu-site .right-select{
  position: relative;
}
#menu-site .right-select:after{
  content: '\f0da';
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 10px;
  top: 12px;
  font-weight: 900;
}
#menu-site .sub-item{
  z-index: 9999;

}
.hc-offcanvas-nav{
  z-index: 1!important;
}
@media (max-width:  992px){
.hc-offcanvas-nav.nav-open{
  z-index: 9999!important;

}
}

@media (min-width:  1800px){

  #menu-site ul li ul{
    width: 100%;
  }
}





#menu-site .menu_link .construction{
  color: red;
  text-align: center;
  background: white;
  width: 100%;
  margin-bottom: 12px;
}


#menu-site .menu_link:hover .construction:after{
  content: 'En construction';
  position: absolute;
  display: block;
  border: solid 1px;
  background: white;
  padding: 10px 0;
  top: 100%;
  left: 0;
  right: 0;
}

nav a {
  img{
  @include media-breakpoint-down(md) {
    display: none!important;
  }
  }
}
nav.nav-open a{
  color: initial!important;
}
.hc-offcanvas-nav.nav-open[class*="hc-nav-"] div.nav-container,
.hc-offcanvas-nav.nav-levels-overlap ul li.level-open > .nav-wrapper{
  -webkit-transform: translate3d(0, 0, 0)!important;;
  transform: translate3d(0, 0, 0)!important;;
}


#menu-site .nav-item{
  min-width: 104px;
}
















