
table {
  width: 100%;
}
#export-choice{
  .famille, .alert {
    position: relative;
  }
  table{
    width: 100%;
    tr{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 5px;
    }
    tr:nth-child(odd){
      background:  lightgray
    }
  }
  .form-check-input{
    position: inherit
  }
}