
.amount_wrong:after {
  content: '\f06a';
  position: absolute;
  right: 5px;
  top: 50%;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  color: $errorColorDpm;
  transform: translate(0, -50%)
}
.amount_ok:after {
  content: '\f058';
  position: absolute;
  right: 5px;
  top: 50%;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  color: $greenDpm;
  transform: translate(0, -50%)
}





#selection_address{
  position: relative;
  ul{
    position: absolute;
    background: white;
    z-index: 9;
    left: 15px;
    right: 15px;
    border: solid 1px #ced4da;
    padding: 10px;
    border-top: none;
    border-radius: 0 0 10px 10px;
    li {
      color: #000000;
      padding: 10px 5px;
      font-weight: normal;
      span{
        padding-right: 5px;
      }
    }
    li:hover {
      cursor: pointer;
      background: $dpm_color;
      color: white;
      padding: 10px 5px;
    }
  }
}
#member-template .btn-filter,
#family-desc .btn-filter,
#animal-template .btn-filter{
  display: none
}

.roles_check > div,
.roles_check #edit_roles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.roles_check > div > div label {
  margin-right: 1rem;
  display: flex !important;;
  align-items: center;
  margin-bottom: 0;
}



#checklist_missing{
  position: fixed;
  top: 11%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 9;
  .content{
    width: 50%;
    margin: auto;
    background: white;
    padding: 10px;
    margin-top: 10vh;
    h3{
      text-align: center;
      margin-bottom: 40px;
    }
    ul{
      li{
        margin-top: 10px;
        i{
          margin-left: 10px
        }
      }
    }
  }
}

table {
  .actions {
    display: flex;
    justify-content: space-evenly;
    padding: 5px 0 !important;
    li{
      text-align: center;
    }
  }
}
table .actions li{
  margin-bottom: 10px;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;

  }
}
table .actions>a {
  margin: 0 2px
}
.fade.in{
  opacity: 1;
}