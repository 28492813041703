#config-wrapper {
  .form_content {
    position: relative;

    .close {
      font-size: 20px;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 1
    }

    #icons_fa {
      width: 90%;
      position: absolute;
      background: #fff;
      height: 50vh;
      overflow: auto;
      padding: 1rem 2rem;
      left: 5%;
      border: solid 1px;
      z-index: 1000;
      bottom: 0;

      .icons_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .icon_select {
          font-size: 2rem;
          margin-right: 2rem;
          margin-bottom: 2rem;
          cursor: pointer
        }
      }

    }

    #app_bundle_statut_type_icon {
      position: relative;
    }

    .icon_display_js {
      color: #495057;
      font-size: 20px;
      position: absolute;
      bottom: 0;
      right: 15px;
      left: 15px;
      border: 1px solid #ced4da;
      display: flex;
      align-items: center;
      background: white;
      padding-left: 10px;
      pointer-events: none;
      height: 34px;
      border-radius: 5px;
    }
  }
}

#family-wrapper {
  .select_vaccin {
    height: 100%;
    select {
      height: 75%;
    }
  }
  hr{
    border-color: $dpm_color;
    border-width: 3px;
    margin: 2px
  }
}