//.nav-tabs{
//  margin: 0 0 15px 0;
//  border-bottom: none;
//
//  li{
//    a.nav-link{
//      background: #DEDEDE;
//      border-color: $txt_color_dark;
//    }
//    a.nav-link.active{
//      background: #FFFFFF;
//      border-color: $txt_color_dark;
//      border-bottom: none;
//    }
//  }
//}

#tile-1 {
  .nav-tabs {
    position: relative;
    border: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    li {
      a {
        position: relative;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .active {
      background-color: transparent !important;
      border: none !important;
      //color: #39bcd3 !important;
    }
  }
  .slider {
    display: inline-block;
    width: 30px;
    height: 4px;
    border-radius: 3px;
    background-color: #59BDBD;
    position: absolute;
    z-index: 99;
    bottom: 0;
    transition: all .4s linear;
  }
}
