.creation_form {
  .discount_choice, {
    display: flex;
    align-items: flex-end;
    .form-group {
      margin: 0;
      width: 100%;
      height: 33.5px;
      .checkbox {
        height: 100%;
      }
      label {
        margin: 0;
        display: flex;
        align-items: center;
        height: 100% !important;
        .toggle {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  .subs_details {
    .form-group {
      margin: 0;
    }
  }
  .reduction_type .checkbox {
    label {
      margin: 0;
      .toggle{
        width: 98% !important;
        height: 100% !important;
      }
    }
  }
}
