@charset "UTF-8";
/*********************************************
    IMPORT BOOTSRAP
 */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(~jquery-ui/themes/base/all.css);
@import url(../js/hc-offcanvas/hc-offcanvas-nav.css);
@import url(datetimepicker/bootstrap-datetimepicker.min.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/**********************************************************
IMPORT DES FICHIERS SCSS
 */
html {
  font-size: 14px;
}

body {
  font-size: calc(1em + 0.2vw);
  line-height: 1.2;
  color: #FFFFFF;
  font-weight: 400;
}

a {
  color: #0056be;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 10, 2019 */
@font-face {
  font-family: 'overpassheavy';
  src: url("../fonts/overpass/overpass-heavy-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassheavy_italic';
  src: url("../fonts/overpass/overpass-heavy-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-heavy-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassitalic';
  src: url("../fonts/overpass/overpass-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasslight';
  src: url("../fonts/overpass/overpass-light-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasslight_italic';
  src: url("../fonts/overpass/overpass-light-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-light-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassregular';
  src: url("../fonts/overpass/overpass-regular-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasssemibold';
  src: url("../fonts/overpass/overpass-semibold-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasssemibold_italic';
  src: url("../fonts/overpass/overpass-semibold-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-semibold-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassthin';
  src: url("../fonts/overpass/overpass-thin-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassthin_italic';
  src: url("../fonts/overpass/overpass-thin-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-thin-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedthin';
  src: url("../fonts/saira/sairasemicondensed-thin-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedThIt';
  src: url("../fonts/saira/sairasemicondensed-thinitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairathin';
  src: url("../fonts/saira/saira-thin-webfont.woff2") format("woff2"), url("../fonts/saira/saira-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairathin_italic';
  src: url("../fonts/saira/saira-thinitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondenseditalic';
  src: url("../fonts/saira/sairasemicondensed-italic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedlight';
  src: url("../fonts/saira/sairasemicondensed-light-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedLtIt';
  src: url("../fonts/saira/sairasemicondensed-lightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedmedium';
  src: url("../fonts/saira/sairasemicondensed-medium-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedMdIt';
  src: url("../fonts/saira/sairasemicondensed-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedregular';
  src: url("../fonts/saira/sairasemicondensed-regular-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedsemibold';
  src: url("../fonts/saira/sairasemicondensed-semibold-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedSBdIt';
  src: url("../fonts/saira/sairasemicondensed-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedblack';
  src: url("../fonts/saira/sairasemicondensed-black-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedBlkIt';
  src: url("../fonts/saira/sairasemicondensed-blackitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedbold';
  src: url("../fonts/saira/sairasemicondensed-bold-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedBdIt';
  src: url("../fonts/saira/sairasemicondensed-bolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedextrabold';
  src: url("../fonts/saira/sairasemicondensed-extrabold-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedXBdIt';
  src: url("../fonts/saira/sairasemicondensed-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedextralight';
  src: url("../fonts/saira/sairasemicondensed-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedXLtIt';
  src: url("../fonts/saira/sairasemicondensed-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight';
  src: url("../fonts/saira/saira-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight_italic';
  src: url("../fonts/saira/saira-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaitalic';
  src: url("../fonts/saira/saira-italic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairalight';
  src: url("../fonts/saira/saira-light-webfont.woff2") format("woff2"), url("../fonts/saira/saira-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairalight_italic';
  src: url("../fonts/saira/saira-lightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairamedium';
  src: url("../fonts/saira/saira-medium-webfont.woff2") format("woff2"), url("../fonts/saira/saira-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairamedium_italic';
  src: url("../fonts/saira/saira-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaregular';
  src: url("../fonts/saira/saira-regular-webfont.woff2") format("woff2"), url("../fonts/saira/saira-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairasemibold';
  src: url("../fonts/saira/saira-semibold-webfont.woff2") format("woff2"), url("../fonts/saira/saira-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairasemibold_italic';
  src: url("../fonts/saira/saira-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedsemibold';
  src: url("../fonts/saira/sairaextracondensed-semibold-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedSBdIt';
  src: url("../fonts/saira/sairaextracondensed-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedthin';
  src: url("../fonts/saira/sairaextracondensed-thin-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedThIt';
  src: url("../fonts/saira/sairaextracondensed-thinitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight';
  src: url("../fonts/saira/saira-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight_italic';
  src: url("../fonts/saira/saira-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condenseditalic';
  src: url("../fonts/saira/sairacondensed-italic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedlight';
  src: url("../fonts/saira/sairacondensed-light-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedlight_italic';
  src: url("../fonts/saira/sairacondensed-lightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedmedium';
  src: url("../fonts/saira/sairacondensed-medium-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedmedium_italic';
  src: url("../fonts/saira/sairacondensed-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedregular';
  src: url("../fonts/saira/sairacondensed-regular-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedbold';
  src: url("../fonts/saira/sairacondensed-bold-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedbold_italic';
  src: url("../fonts/saira/sairacondensed-bolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedextrabold';
  src: url("../fonts/saira/sairacondensed-extrabold-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedXBdIt';
  src: url("../fonts/saira/sairacondensed-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedextralight';
  src: url("../fonts/saira/sairacondensed-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedXLtIt';
  src: url("../fonts/saira/sairacondensed-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairablack';
  src: url("../fonts/saira/saira-black-webfont.woff2") format("woff2"), url("../fonts/saira/saira-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairablack_italic';
  src: url("../fonts/saira/saira-blackitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairabold';
  src: url("../fonts/saira/saira-bold-webfont.woff2") format("woff2"), url("../fonts/saira/saira-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairabold_italic';
  src: url("../fonts/saira/saira-bolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedblack';
  src: url("../fonts/saira/sairacondensed-black-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedblack_italic';
  src: url("../fonts/saira/sairacondensed-blackitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 10, 2019 */
@font-face {
  font-family: 'overpassheavy';
  src: url("../fonts/overpass/overpass-heavy-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassheavy_italic';
  src: url("../fonts/overpass/overpass-heavy-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-heavy-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassitalic';
  src: url("../fonts/overpass/overpass-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasslight';
  src: url("../fonts/overpass/overpass-light-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasslight_italic';
  src: url("../fonts/overpass/overpass-light-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-light-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassregular';
  src: url("../fonts/overpass/overpass-regular-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasssemibold';
  src: url("../fonts/overpass/overpass-semibold-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasssemibold_italic';
  src: url("../fonts/overpass/overpass-semibold-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-semibold-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassthin';
  src: url("../fonts/overpass/overpass-thin-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassthin_italic';
  src: url("../fonts/overpass/overpass-thin-italic-webfont.woff2") format("woff2"), url("../fonts/overpass/overpass-thin-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedthin';
  src: url("../fonts/saira/sairasemicondensed-thin-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedThIt';
  src: url("../fonts/saira/sairasemicondensed-thinitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairathin';
  src: url("../fonts/saira/saira-thin-webfont.woff2") format("woff2"), url("../fonts/saira/saira-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairathin_italic';
  src: url("../fonts/saira/saira-thinitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondenseditalic';
  src: url("../fonts/saira/sairasemicondensed-italic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedlight';
  src: url("../fonts/saira/sairasemicondensed-light-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedLtIt';
  src: url("../fonts/saira/sairasemicondensed-lightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedmedium';
  src: url("../fonts/saira/sairasemicondensed-medium-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedMdIt';
  src: url("../fonts/saira/sairasemicondensed-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedregular';
  src: url("../fonts/saira/sairasemicondensed-regular-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedsemibold';
  src: url("../fonts/saira/sairasemicondensed-semibold-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedSBdIt';
  src: url("../fonts/saira/sairasemicondensed-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedblack';
  src: url("../fonts/saira/sairasemicondensed-black-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedBlkIt';
  src: url("../fonts/saira/sairasemicondensed-blackitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedbold';
  src: url("../fonts/saira/sairasemicondensed-bold-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedBdIt';
  src: url("../fonts/saira/sairasemicondensed-bolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedextrabold';
  src: url("../fonts/saira/sairasemicondensed-extrabold-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedXBdIt';
  src: url("../fonts/saira/sairasemicondensed-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedextralight';
  src: url("../fonts/saira/sairasemicondensed-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_semicondensedXLtIt';
  src: url("../fonts/saira/sairasemicondensed-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairasemicondensed-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight';
  src: url("../fonts/saira/saira-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight_italic';
  src: url("../fonts/saira/saira-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaitalic';
  src: url("../fonts/saira/saira-italic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairalight';
  src: url("../fonts/saira/saira-light-webfont.woff2") format("woff2"), url("../fonts/saira/saira-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairalight_italic';
  src: url("../fonts/saira/saira-lightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairamedium';
  src: url("../fonts/saira/saira-medium-webfont.woff2") format("woff2"), url("../fonts/saira/saira-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairamedium_italic';
  src: url("../fonts/saira/saira-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaregular';
  src: url("../fonts/saira/saira-regular-webfont.woff2") format("woff2"), url("../fonts/saira/saira-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairasemibold';
  src: url("../fonts/saira/saira-semibold-webfont.woff2") format("woff2"), url("../fonts/saira/saira-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairasemibold_italic';
  src: url("../fonts/saira/saira-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedsemibold';
  src: url("../fonts/saira/sairaextracondensed-semibold-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedSBdIt';
  src: url("../fonts/saira/sairaextracondensed-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedthin';
  src: url("../fonts/saira/sairaextracondensed-thin-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_extracondensedThIt';
  src: url("../fonts/saira/sairaextracondensed-thinitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairaextracondensed-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight';
  src: url("../fonts/saira/saira-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairaextralight_italic';
  src: url("../fonts/saira/saira-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condenseditalic';
  src: url("../fonts/saira/sairacondensed-italic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedlight';
  src: url("../fonts/saira/sairacondensed-light-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedlight_italic';
  src: url("../fonts/saira/sairacondensed-lightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedmedium';
  src: url("../fonts/saira/sairacondensed-medium-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedmedium_italic';
  src: url("../fonts/saira/sairacondensed-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedregular';
  src: url("../fonts/saira/sairacondensed-regular-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedbold';
  src: url("../fonts/saira/sairacondensed-bold-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedbold_italic';
  src: url("../fonts/saira/sairacondensed-bolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedextrabold';
  src: url("../fonts/saira/sairacondensed-extrabold-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedXBdIt';
  src: url("../fonts/saira/sairacondensed-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedextralight';
  src: url("../fonts/saira/sairacondensed-extralight-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedXLtIt';
  src: url("../fonts/saira/sairacondensed-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairablack';
  src: url("../fonts/saira/saira-black-webfont.woff2") format("woff2"), url("../fonts/saira/saira-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairablack_italic';
  src: url("../fonts/saira/saira-blackitalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairabold';
  src: url("../fonts/saira/saira-bold-webfont.woff2") format("woff2"), url("../fonts/saira/saira-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sairabold_italic';
  src: url("../fonts/saira/saira-bolditalic-webfont.woff2") format("woff2"), url("../fonts/saira/saira-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedblack';
  src: url("../fonts/saira/sairacondensed-black-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saira_condensedblack_italic';
  src: url("../fonts/saira/sairacondensed-blackitalic-webfont.woff2") format("woff2"), url("../fonts/saira/sairacondensed-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/********************************************
BTN GEN
 */
.btn-gen {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #666666;
  font-size: 1.02vw;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #666666;
  border-radius: 20px;
  padding: 2px 15px;
  vertical-align: middle;
  /** En dessous de **/
}

@media (max-width: 767.98px) {
  .btn-gen {
    font-size: 3.125vw;
  }
}

.btn-gen:hover {
  color: #ffffff;
  background: #666666;
  text-decoration: none;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

/********************************************
BTN AVEC ICONE
 */
.btn-login:before {
  content: "\f2f6";
  /* >> Name of the FA free font (mandatory)
             - 'Font Awesome 5 Free' for Regular and Solid symbols;
             - 'Font Awesome 5 Brand' for Brands symbols. */
  font-family: 'Font Awesome 5 Free';
  /* >> Weight of the font (mandatory)
             - 400 for Regular and Brands symbols;
             - 900 for Solid symbols. */
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-new:before {
  content: "\f067";
  /* >> Name of the FA free font (mandatory)
             - 'Font Awesome 5 Free' for Regular and Solid symbols;
             - 'Font Awesome 5 Brand' for Brands symbols. */
  font-family: 'Font Awesome 5 Free';
  /* >> Weight of the font (mandatory)
             - 400 for Regular and Brands symbols;
             - 900 for Solid symbols. */
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-filters:before {
  content: "\f0b0";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-save:before {
  content: "\f0c7";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-see:before {
  content: "\f06e";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-add:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-edit:before {
  content: "\f044";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-list:before {
  content: "\f0ca";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-csv:before {
  content: "\f1c3";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-upload:before {
  content: "\f093";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-download:before {
  content: "\f019";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-trash:before {
  content: "\f1f8";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-logout:before {
  content: "\f2f5";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-cogs:before {
  content: "\f085";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

.btn-check:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin: 0 0 0 0;
}

table .btn-sm, table .btn-group-sm > .btn {
  line-height: 1.2;
}

.btn-er {
  background: #fff;
  color: #c82333;
  border: solid 1px #c82333 !important;
}

.btn-er:hover {
  background: #c82333;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-close {
  color: #FF8C00;
  background: #fff;
  border: solid 1px #FF8C00 !important;
}

.btn-close:hover {
  background: #FF8C00;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-reset {
  color: #17a2b8;
  background: #fff;
  border: solid 1px #17a2b8 !important;
}

.btn-reset:hover {
  background: #17a2b8;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-next,
.btn-see,
.btn-ajout {
  color: #28a745;
  background: #fff;
  border: solid 1px #28a745 !important;
}

.btn-next:hover,
.btn-see:hover,
.btn-ajout:hover {
  background: #28a745;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-cxl {
  color: #8B0000;
  background: #fff;
  border: solid 1px #8B0000 !important;
}

.btn-cxl:hover {
  background: #8B0000;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-edit {
  color: #D2691E;
  background: #fff;
  border: solid 1px #D2691E !important;
}

.btn-edit:hover {
  background: #D2691E;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-reload {
  color: #17a2b8;
  background: #fff;
  border: solid 1px #17a2b8   !important;
}

.btn-reload:hover {
  background: #17a2b8;
  color: #fff;
  border: none;
  box-shadow: none;
}

.btn-see-orange {
  color: #D2691E;
  background: #fff;
  border: solid 1px #D2691E !important;
}

.btn-see-orange:hover {
  background: #D2691E;
  color: #fff;
  border: none;
  box-shadow: none;
}

.email_drop {
  width: 100% !important;
  padding-left: 10px !important;
  color: #17a2b8 !important;
}

.email_drop:hover {
  color: #fff !important;
  text-decoration: none;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #ffffff;
  background: #6c757d;
}

.btn-rgpd {
  color: #dc3545;
}

#close_preview {
  cursor: pointer;
}

.btn-dpm {
  color: #59BDBD;
  background-color: #ffffff;
  border-color: #59BDBD;
}

.btn-dpm:hover {
  color: #59BDBD;
  background: #ffffff;
}

.delete_project_document,
.delete_project {
  cursor: pointer;
}

.btn-show {
  color: #bfbfbf;
  background: #fff;
  border: solid 1px #bfbfbf !important;
}

.btn-show:hover {
  background: #bfbfbf;
  color: #fff;
  border: none;
  box-shadow: none;
}

.moutarde {
  color: #ffc107 !important;
}

.vert {
  color: #28a745 !important;
}

.vert.btn:hover {
  background: #28a745 !important;
  color: white !important;
}

.red {
  color: #c82333 !important;
}

.pourpre {
  color: #8b0000 !important;
}

.brown {
  color: #93282a !important;
}

.blue {
  color: #4169E1 !important;
}

.blue.btn:hover {
  background: #4169E1;
  color: white !important;
}

.blue_light {
  color: #97bfe3 !important;
}

.grey {
  color: #bfbfbf !important;
}

.orange {
  color: #D2691E !important;
}

.orange_dpm {
  color: #f8aa6a;
}

.orange_dpm.btn:hover {
  background: #f8aa6a !important;
  color: white !important;
}

.blue_dpm {
  color: #59BDBD !important;
}

.blue_dpm.btn:hover {
  background: #59BDBD !important;
  color: white !important;
}

.red_strong {
  color: #D80A0A !important;
}

.yellow {
  color: #eec64d !important;
}

.green_dpm {
  color: #3cb371 !important;
}

.green_dpm.btn:hover {
  background: #3cb371 !important;
  color: white !important;
}

.purple_dpm {
  color: #da70d6 !important;
}

.purple_dpm.btn:hover {
  background: #da70d6 !important;
  color: white !important;
}

.blue_reload {
  color: #17a2b8 !important;
}

#form-login h1 {
  display: block;
}

#form-login #titre {
  margin-top: 5%;
  margin-bottom: 0;
  text-align: center;
  font-family: 'Overpass', cursive;
  font-size: 90px;
  color: #525252;
}

#form-login .login-container {
  padding: 30px 30px 10px;
  border-radius: 10px;
  width: 75% !important;
  background-color: #ffffff;
  margin: 60px auto 60px auto;
  box-shadow: 0 2px 30px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-size: 12px;
}

#form-login .login-container h1 {
  text-align: center;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  #form-login .login-container h1 {
    font-size: 2rem;
  }
}

#form-login .login-container input[type=submit] {
  width: 100%;
  display: block;
  margin: 10px 0;
  position: relative;
  padding: 2%;
}

#form-login .login-container input[type=text], #form-login .login-container input[type=password] {
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#form-login .login-container input[type=text]:hover, #form-login .login-container input[type=password]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

#form-login .login-submit {
  border: 0;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  background-color: #59BDBD;
  border-radius: 10px;
  padding: 17px 0;
  cursor: pointer;
  font-size: 20px;
}

#form-login .login-submit a {
  text-decoration: none;
  color: #666;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  opacity: 0.6;
  transition: opacity ease 0.5s;
}

#form-login .login-submit:hover {
  border: 0;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
}

#form-login .login-help {
  font-size: 12px;
}

#form-login #logo-ok {
  margin-top: 1%;
  width: 10%;
}

@media (max-width: 1199.98px) {
  #form-login #logo-ok {
    width: 15%;
  }
}

@media (max-width: 767.98px) {
  #form-login #logo-ok {
    width: 20%;
  }
}

#form-login #options {
  width: 80%;
  /*si a gauche*/
  margin-left: 15%;
}

#form-login .bg-id {
  background-image: url("../../images/backgroundweb2.png");
  background-repeat: no-repeat;
  background-size: 63%;
  background-position: center;
  max-width: 1200px;
  margin: auto;
}

#form-login .bg-id img {
  width: 65%;
  margin-top: 60px;
}

.table-wrapper-responsive {
  max-width: 100%;
  overflow: auto;
}

table.sortable tr {
  cursor: move;
}

.table-thumbnail {
  max-width: 150px;
}

/**********************************************************
ALERTS
 */
.alert-fixed {
  position: fixed;
  z-index: 5;
  top: 30%;
  width: 100%;
  text-align: center;
}

.alert-fixed .alert {
  cursor: pointer;
  display: inline-block;
}

.alert-fixed .alert .button {
  margin-left: 20px;
}

.alert-center {
  margin: 0 auto;
}

/*********************************************************************
FIL D'ARIANNE
*********************************************************************/
#breadcrumb {
  padding: 0 0 0 120px;
  height: 40px;
  line-height: 40px;
  background: #f9f6ed;
}

#breadcrumb ul {
  padding: 0;
  margin: 0;
}

#breadcrumb ul li {
  list-style: none;
  float: left;
}

#breadcrumb ul li a {
  padding: 0 5px;
  font-size: 1rem;
  color: #5d5858;
}

#breadcrumb ul li a:hover {
  text-decoration: none;
}

#breadcrumb ul li a.last {
  color: #e5694f;
}

#breadcrumb ul li:last-child a {
  text-decoration: none;
}

#tile-1 .nav-tabs {
  position: relative;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

#tile-1 .nav-tabs li a {
  position: relative;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tile-1 .nav-tabs .active {
  background-color: transparent !important;
  border: none !important;
}

#tile-1 .slider {
  display: inline-block;
  width: 30px;
  height: 4px;
  border-radius: 3px;
  background-color: #59BDBD;
  position: absolute;
  z-index: 99;
  bottom: 0;
  transition: all .4s linear;
}

.checkbox-inline .checkbox {
  display: inline-block;
}

.checkbox label .toggle, .checkbox-inline .toggle {
  margin-left: 0 !important;
}

.check {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.checkbox-inline #organisme_edit_animalType label {
  display: flex !important;
  align-items: center;
  margin-right: 10px;
}

.radio-inline-block .radio {
  display: inline-block;
  margin: 0 0 0 15px;
}

.radio-inline-block .radio:first-child {
  margin: 0;
}

.password-seeable {
  position: relative;
}

.password-seeable i {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 37px;
  font-size: 1.02vw;
  font-weight: 900;
  margin: 0 10px 0 0;
  cursor: pointer;
}

/*
.datepicker{
  display: flex;
  flex-wrap: wrap;
  margin:0 0 10px 0;

  label:first-child{
    width: 100%;
  }

  select{
    margin:0 10px 0 0;
  }

  .datepicker-widget,
  .birthdaypicker-widget,
  .js-datepicker{
    display:none;
    position: absolute;
    z-index: 2;
  }

  .datepicker-widget.visible,
  .birthdaypicker-widget.visible,
  .js-datepicker.visible{
    display:block;
  }

  i{
    font-size:2rem;
    cursor:pointer;
  }
}
*/
form .form-error-message {
  color: red;
  display: none;
}

form .has-error input,
form .has-error select,
form .has-error textarea {
  border: 1px solid red;
}

form .has-error .help-block {
  color: red;
}

form .has-error .form-error-message {
  display: block;
}

form .alert.alert-danger .list-unstyled {
  margin: 0;
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-group-display-inline-block > div > div {
  display: inline-block;
}

.compulsory:after {
  content: ' *';
  color: red;
}

.mandatory_form:after {
  content: "*";
  color: #8b0000;
  margin-left: 5px;
}

.select2-container--open {
  z-index: 9999999;
}

input.updated {
  background-color: yellow;
}

.button-hover-input-wrapper {
  position: relative;
}

.button-hover-input-wrapper .hover-input {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0 0.25rem 0.25rem 0;
  display: inline-block;
  background: #ccc;
  padding: 5px 10px;
  cursor: pointer;
}

.button-hover-input-wrapper .hover-input i {
  vertical-align: middle;
}

/*//modif bouton upload*/
#FakeInput {
  position: relative;
  overflow: hidden;
}

#FakeInput input {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

#FakeInput span {
  display: inline-block;
  vertical-align: top;
}

#FakeInput span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  height: 25px;
  padding-left: 5px;
  border: 1px solid #ccc;
  text-overflow: ellipsis;
  font: 100%/25px sans-serif;
  color: #666;
  border-radius: 3px;
}

#FakeInput span + span {
  margin-left: 5px;
  text-align: center;
  text-decoration: underline;
}

#FakeInput label {
  display: initial;
}

#project-container #FakeInput label {
  display: none;
}

/*********************** couleur DEFAULT ***********************/
fieldset {
  margin: 0;
  padding: 5px;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

fieldset.fieldset_blue {
  border: solid 1px #59BDBD;
  border-top: none;
  padding: 10px;
}

legend {
  margin: 15px 0 0 0;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #333333;
  border-radius: 5px;
}

legend.main {
  border-color: #17a2b8;
}

legend.legend_blue.cross:after {
  content: " ";
  height: 25px;
  width: 25px;
  top: 45%;
  transform: translate(-50%, -50%);
}

legend.active {
  border: solid 1px #333333;
  border-radius: 5px 5px 0 0;
}

legend.active:after {
  content: " ";
  top: 15px !important;
  transform: rotate(-180deg) translate(0, 0) !important;
}

legend.blue_border.cross {
  border: solid 1px #59BDBD !important;
}

legend.active.blue_border {
  border: solid 1px #59BDBD !important;
}

legend.orange_border.cross {
  border: solid 1px #f8aa6a !important;
}

legend.active.orange_border {
  border: solid 1px #f8aa6a !important;
}

.amount_wrong:after {
  content: '\f06a';
  position: absolute;
  right: 5px;
  top: 50%;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  color: #8b0000;
  transform: translate(0, -50%);
}

.amount_ok:after {
  content: '\f058';
  position: absolute;
  right: 5px;
  top: 50%;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  color: #3cb371;
  transform: translate(0, -50%);
}

#selection_address {
  position: relative;
}

#selection_address ul {
  position: absolute;
  background: white;
  z-index: 9;
  left: 15px;
  right: 15px;
  border: solid 1px #ced4da;
  padding: 10px;
  border-top: none;
  border-radius: 0 0 10px 10px;
}

#selection_address ul li {
  color: #000000;
  padding: 10px 5px;
  font-weight: normal;
}

#selection_address ul li span {
  padding-right: 5px;
}

#selection_address ul li:hover {
  cursor: pointer;
  background: #59BDBD;
  color: white;
  padding: 10px 5px;
}

#member-template .btn-filter,
#family-desc .btn-filter,
#animal-template .btn-filter {
  display: none;
}

.roles_check > div,
.roles_check #edit_roles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.roles_check > div > div label {
  margin-right: 1rem;
  display: flex !important;
  align-items: center;
  margin-bottom: 0;
}

#checklist_missing {
  position: fixed;
  top: 11%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

#checklist_missing .content {
  width: 50%;
  margin: auto;
  background: white;
  padding: 10px;
  margin-top: 10vh;
}

#checklist_missing .content h3 {
  text-align: center;
  margin-bottom: 40px;
}

#checklist_missing .content ul li {
  margin-top: 10px;
}

#checklist_missing .content ul li i {
  margin-left: 10px;
}

table .actions {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 0 !important;
}

table .actions li {
  text-align: center;
}

table .actions li {
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  table .actions li {
    margin-bottom: 20px;
  }
}

table .actions > a {
  margin: 0 2px;
}

.fade.in {
  opacity: 1;
}

.home_header > div {
  display: flex;
  align-items: center;
}

.home_header h1 {
  font-size: 1.2rem !important;
  margin: 0;
}

.home_header h2 {
  font-size: 1.5rem !important;
  margin: 0 auto;
}

.home_header .logo_home img {
  width: 100%;
}

.stats .last_five .titre.h5 {
  display: flex;
  align-items: flex-end;
  margin: 0;
}

.stats .last_five .titre.h5 h5 {
  margin: 0 auto;
  height: 30px;
  font-size: 1.5rem;
  color: #666;
  font-weight: bold;
}

.stats .last_five .content {
  height: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
}

.stats .last_five .content .last_content {
  border: solid 1px #59BDBD;
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 5px;
  max-height: 35px;
}

.stats .last_five .content .last_content div {
  width: calc(100% / 5);
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 1%;
}

.stats .last_five .content .last_content div:not(:first-child) {
  text-align: center;
}

.stats .last_five .content .last_content div:not(:first-child) i {
  margin-left: 5px;
}

.stats .last_five .content .last_content div:first-child i {
  margin-right: 5px;
}

.stats .last_five .content .last_content div i {
  font-size: 14px;
}

@media (max-width: 991.98px) {
  .stats .last_five .content .last_content div:last-child {
    width: 11%;
  }
  .stats .last_five .content .last_content div:nth-child(4) {
    width: 11%;
  }
  .stats .last_five .content .last_content div:first-child {
    width: 45%;
  }
}

.tab {
  cursor: pointer;
}

.tab h2 {
  padding: 10px 0;
  position: relative;
}

.tab h2:after {
  transition: all ease-in-out .2s;
  background: none repeat scroll 0 0 #59BDBD;
  content: "";
  display: block;
  height: 1px;
  width: 0;
  position: absolute;
  bottom: 0;
}

.tab:hover h2:after {
  width: 100%;
}

.tab.active h2:after {
  width: 100%;
}

.animal_content .tabi .nav-tabs {
  border: none;
  border-bottom: 1px solid #079fc9;
  margin: 0;
}

.animal_content .tabi .nav-tabs li {
  display: flex;
}

.animal_content .tabi .nav-tabs li a {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px 0 0;
  font-weight: 600;
  text-transform: capitalize;
  border: 1px solid #e6e5e1;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  z-index: 1;
  position: relative;
  transition: all 0.3s ease 0s;
}

.animal_content .tabi .nav-tabs li a:hover {
  background: #fff;
  border: 1px solid #079fc9;
  margin: 0 10px -1px 0;
}

.animal_content .tabi .nav-tabs li a:hover:before {
  transform: scaleX(1);
}

.animal_content .tabi .nav-tabs li a:before {
  content: "";
  display: block;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.animal_content .tabi .nav-tabs li.active a {
  background: #fff;
  border: 1px solid #079fc9;
  margin: 0 10px -1px 0;
}

.animal_content .tabi .nav-tabs li.active a:before {
  transform: scaleX(1);
}

.animal_content .tabi .tab-content {
  padding: 10px;
}

@media only screen and (max-width: 479px) {
  .animal_content .tabi .nav-tabs {
    border: none;
  }
  .animal_content .tabi .nav-tabs li a {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 1px solid transparent;
  }
  .animal_content .tabi .nav-tabs li a:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #079fc9;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
}

#configuration-page.modules h2:after {
  content: '';
  display: block;
  width: 10%;
  height: 2px;
  background: #71b8ab;
  position: absolute;
  left: 45%;
  bottom: -10px;
}

#configuration-page.modules h2 {
  position: relative;
  margin-bottom: 30px;
}

#configuration-page.modules .vignette {
  padding: 30px;
  box-shadow: 0px 8px 20px -12px rgba(0, 0, 0, 0.75);
  margin-bottom: 50px !important;
  min-height: 320px !important;
}

#configuration-page.modules > .container {
  padding: 30px;
}

#configuration-page.modules .vignette .details {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 10px;
}

@media (max-width: 991.98px) {
  #configuration-page.modules .vignette .details {
    position: static;
  }
}

#configuration-page.modules .image_block {
  text-align: center;
}

#configuration-page.modules .image_block img {
  width: 40px;
}

#building {
  background: red;
  color: white;
  padding: 10px;
  width: 240px;
  position: absolute;
  left: -8%;
  top: 10%;
  transform: rotate(-35deg);
  text-align: center;
}

@media (max-width: 991.98px) {
  #building {
    left: -17%;
    top: 7%;
  }
}

#configuration-page .vignette {
  overflow: hidden;
  position: relative;
}

#member-template #global-details i, #member-template #category-details i {
  margin-right: 10px;
}

#animal-template #global-animal i, #animal-template #detail-animal i {
  margin-right: 10px !important;
}

#animal-template .identity {
  display: flex;
  align-items: center;
}

#animal-template .identity img {
  width: 15px;
  margin-right: 9px;
}

#identification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}

#identification .container {
  box-shadow: none;
  border: none;
}

#identification .container .psswrd .form-group,
#identification .container #identification .psswrd input {
  margin-bottom: 0 !important;
}

#identification .container .btn-mdp {
  text-align: left;
  display: block;
  margin: 0;
}

#identification .container .remember_me label {
  font-size: 80% !important;
  display: flex;
  justify-content: flex-end;
}

#identification .container .remember_me input[type="checkbox"] {
  margin-right: 5px;
  height: 13px;
  width: 13px;
}

#identification .container .remember_me .toggle {
  min-height: 18px !important;
  min-width: 20px;
}

#identification .container .remember_me .toggle-handle {
  vertical-align: top;
  padding: 0 5px;
}

#new_organisme .rgpd label, #form_absolute .rgpd label {
  font-size: 14px;
  display: flex;
  align-items: center;
}

/*********************************************
OVERRIDE DE HCOFFCANVAS POUR VERSION DESKTOP
 */
#menu-sidebar {
  display: none;
}

.hc-offcanvas-nav .nav-close a:hover {
  color: #FFFFFF;
}

.hc-offcanvas-nav .nav-container {
  height: 100vh;
  overflow: auto;
}

@media (max-width: 991.98px) {
  .hc-offcanvas-nav .nav-container {
    margin-top: 0;
  }
}

.hc-offcanvas-nav .nav-wrapper-2 a {
  padding: 14px 17px 14px 27px;
}

.hc-nav-2 {
  left: 100px;
  position: fixed;
}

.hc-nav-2 > span:before,
.hc-nav-2 > span:after,
.hc-nav-2 > span {
  background: #8b0000 !important;
}

/** MD et au dessus **/
@media (min-width: 992px) {
  nav.hc-offcanvas-nav {
    visibility: visible;
  }
  .hc-nav-trigger.hc-nav-1 {
    display: none !important;
  }
  .hc-offcanvas-nav.hc-nav-1.nav-position-left .nav-container {
    transform: translate3d(0, 0, 0) !important;
  }
  .hc-offcanvas-nav .nav-close {
    display: none;
  }
}

.nav-container {
  margin-top: 80px;
  box-shadow: 10px 10px 20px #00000029;
}

@media (max-width: 991.98px) {
  .nav-container {
    margin-top: 0;
  }
}

.page-container .container {
  margin-top: 10px !important;
  border-radius: 0 !important;
  box-shadow: none;
  padding-bottom: 30px;
}

.page-container {
  padding-top: 20px;
  min-height: 100vh;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  padding-left: 250px;
  padding-right: 10px;
}

@media (max-width: 991.98px) {
  .page-container {
    padding-left: 10px;
  }
}

#config-wrapper .container {
  max-width: unset;
  min-height: 100vh;
}

#edit_gender {
  /*   display: flex; */
  padding: 25px 0 0;
  display: inline-flex;
}

#edit_gender .radio input {
  position: absolute;
  left: -20px;
}

#edit_gender .radio label {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 10px;
}

#config-wrapper > div > .container {
  border: solid #8b0000  1px;
}

.edit_mdp {
  color: #8b0000;
  border: solid 1px #8b0000;
  transition: all 0.2s ease-in;
}

#organisme .logo-organisme {
  position: absolute;
  top: 0;
  right: 15px;
  max-height: 100px;
}

#organisme .btn {
  font-weight: bold;
}

#organisme #accordion .panel {
  border-radius: 0;
  border: 0;
  margin-top: 0;
}

#organisme #accordion .row_delete {
  position: static;
}

#organisme #accordion a {
  display: block;
  padding: 15px 10px;
  border: solid 1px #8b0000;
  text-decoration: none;
  color: #8b0000;
}

#organisme #accordion a.btn {
  padding: 5px 0;
}

#organisme #accordion .panel-heading {
  padding: 0;
  border-radius: 0;
  text-align: left;
}

#organisme #accordion .panel-heading a {
  text-transform: uppercase;
}

#organisme #accordion .panel-heading a.collapsed:hover, #organisme #accordion .panel-heading a.collapsed:focus {
  transition: all 0.2s ease-in;
  color: #8b0000;
}

#organisme #accordion .panel-heading a:not(.collapsed) {
  color: #8b0000;
  border: solid 1px #8b0000;
  transition: all 0.2s ease-in;
}

#organisme #accordion .panel-heading .accordion-toggle {
  text-align: left;
  cursor: pointer;
}

#organisme #accordion .panel-heading .accordion-toggle::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: "\f00d";
  float: right;
  color: #8b0000;
  transform: rotate(0deg);
  transition: all 0.2s ease-in;
}

#organisme #accordion .panel-heading .accordion-toggle.collapsed::before {
  color: #8b0000;
  transform: rotate(-135deg);
  transition: all 0.2s ease-in;
}

#parametre-cron li {
  width: 100% !important;
}

#crud form .validation {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 12;
}

#crud form .content {
  overflow: auto;
}

#crud form .content thead tr {
  box-shadow: 0 3px 3px #999;
}

#crud form .content thead tr th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  box-shadow: 3px 3px 3px #999;
}

#crud form .content input:hover {
  cursor: pointer;
}

#config-wrapper .form_content {
  position: relative;
}

#config-wrapper .form_content .close {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
}

#config-wrapper .form_content #icons_fa {
  width: 90%;
  position: absolute;
  background: #fff;
  height: 50vh;
  overflow: auto;
  padding: 1rem 2rem;
  left: 5%;
  border: solid 1px;
  z-index: 1000;
  bottom: 0;
}

#config-wrapper .form_content #icons_fa .icons_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#config-wrapper .form_content #icons_fa .icons_content .icon_select {
  font-size: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

#config-wrapper .form_content #app_bundle_statut_type_icon {
  position: relative;
}

#config-wrapper .form_content .icon_display_js {
  color: #495057;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 15px;
  left: 15px;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  background: white;
  padding-left: 10px;
  pointer-events: none;
  height: 34px;
  border-radius: 5px;
}

#family-wrapper .select_vaccin {
  height: 100%;
}

#family-wrapper .select_vaccin select {
  height: 75%;
}

#family-wrapper hr {
  border-color: #59BDBD;
  border-width: 3px;
  margin: 2px;
}

table {
  width: 100%;
}

#export-choice .famille, #export-choice .alert {
  position: relative;
}

#export-choice table {
  width: 100%;
}

#export-choice table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}

#export-choice table tr:nth-child(odd) {
  background: lightgray;
}

#export-choice .form-check-input {
  position: inherit;
}

#organisme_checklist.normal {
  position: relative;
  background: white;
}

#organisme_checklist.normal .checklist_details > div:hover {
  background: #59BDBD;
  color: white !important;
}

#organisme_checklist.normal .mandatory {
  border: solid 1px #c82333;
  padding: 2px 5px;
  margin-left: 5px;
  border-radius: 10px;
  color: #c82333;
}

#organisme_checklist.normal .wrapper {
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  position: relative;
}

#organisme_checklist.normal .wrapper a {
  color: #333333 !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 10px;
}

#organisme_checklist.normal .wrapper a h3 {
  font-size: 25px !important;
}

#organisme_checklist.normal .wrapper a .content {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
  border-bottom: solid 1px #59BDBD;
}

#organisme_checklist.normal .wrapper a .content .titre {
  font-size: 20px;
}

#organisme_checklist.normal .wrapper a .content .text {
  font-size: 14px;
}

#minimise_organisme_checklist .green {
  margin-top: 15px;
}

#minimise_organisme_checklist .progresse {
  margin: auto;
}

#minimise_organisme_checklist .green .progresse,
#minimise_organisme_checklist .red .progresse,
#minimise_organisme_checklist .orange .progresse {
  position: relative;
  border-radius: 50%;
}

#minimise_organisme_checklist .green .progresse,
#minimise_organisme_checklist .red .progresse,
#minimise_organisme_checklist .orange .progresse {
  width: 110px;
  height: 110px;
}

#minimise_organisme_checklist .green .progresse {
  border: 5px solid #53fc53;
}

#minimise_organisme_checklist .green .progresse {
  box-shadow: 0 0 20px #029502;
}

#minimise_organisme_checklist .green .progresse,
#minimise_organisme_checklist .red .progresse,
#minimise_organisme_checklist .orange .progresse {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

#minimise_organisme_checklist .green .progresse .inner,
#minimise_organisme_checklist .red .progresse .inner,
#minimise_organisme_checklist .orange .progresse .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
}

#minimise_organisme_checklist .green .progresse .inner,
#minimise_organisme_checklist .red .progresse .inner,
#minimise_organisme_checklist .orange .progresse .inner {
  width: 100px;
  height: 100px;
}

#minimise_organisme_checklist .green .progresse .inner,
#minimise_organisme_checklist .red .progresse .inner,
#minimise_organisme_checklist .orange .progresse .inner {
  border: 5px solid white;
}

#minimise_organisme_checklist .green .progresse .inner,
#minimise_organisme_checklist .red .progresse .inner,
#minimise_organisme_checklist .orange .progresse .inner {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

#minimise_organisme_checklist .green .progresse .inner .water,
#minimise_organisme_checklist .red .progresse .inner .water,
#minimise_organisme_checklist .orange .progresse .inner .water {
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
}

#minimise_organisme_checklist .green .progresse .inner .water {
  top: 25%;
}

#minimise_organisme_checklist .green .progresse .inner .water {
  background: rgba(83, 252, 83, 0.5);
}

#minimise_organisme_checklist .green .progresse .inner .water,
#minimise_organisme_checklist .red .progresse .inner .water,
#minimise_organisme_checklist .orange .progresse .inner .water {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

#minimise_organisme_checklist .green .progresse .inner .water,
#minimise_organisme_checklist .red .progresse .inner .water,
#minimise_organisme_checklist .orange .progresse .inner .water {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}

#minimise_organisme_checklist .green .progresse .inner .water {
  box-shadow: 0 0 20px #03bc03;
}

#minimise_organisme_checklist .green .progresse .inner .glare,
#minimise_organisme_checklist .red .progresse .inner .glare,
#minimise_organisme_checklist .orange .progresse .inner .glare {
  position: absolute;
  top: -120%;
  left: -120%;
  z-index: 5;
  width: 200%;
  height: 200%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}

#minimise_organisme_checklist .green .progresse .inner .glare,
#minimise_organisme_checklist .red .progresse .inner .glare,
#minimise_organisme_checklist .orange .progresse .inner .glare {
  background-color: rgba(255, 255, 255, 0.15);
}

#minimise_organisme_checklist .green .progresse .inner .glare,
#minimise_organisme_checklist .red .progresse .inner .glare,
#minimise_organisme_checklist .orange .progresse .inner .glare {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

#minimise_organisme_checklist .green .progresse .inner .percent,
#minimise_organisme_checklist .red .progresse .inner .percent,
#minimise_organisme_checklist .orange .progresse .inner .percent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  text-align: center;
}

#minimise_organisme_checklist .green .progresse .inner .percent,
#minimise_organisme_checklist .red .progresse .inner .percent,
#minimise_organisme_checklist .orange .progresse .inner .percent {
  line-height: 90px;
  font-size: 25px;
}

#minimise_organisme_checklist .green .progresse .inner .percent {
  color: #03c603;
}

#minimise_organisme_checklist .green .progresse .inner .percent {
  text-shadow: 0 0 10px #029502;
}

#minimise_organisme_checklist .green .progresse .inner .percent,
#minimise_organisme_checklist .red .progresse .inner .percent,
#minimise_organisme_checklist .orange .progresse .inner .percent {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

#minimise_organisme_checklist .red {
  margin-top: 15px;
}

#minimise_organisme_checklist .red .progresse {
  border: 5px solid #ed3b3b;
}

#minimise_organisme_checklist .red .progresse {
  box-shadow: 0 0 20px #7a0b0b;
}

#minimise_organisme_checklist .red .progresse .inner .water {
  top: 75%;
}

#minimise_organisme_checklist .red .progresse .inner .water {
  background: rgba(237, 59, 59, 0.5);
}

#minimise_organisme_checklist .red .progresse .inner .water {
  box-shadow: 0 0 20px #9b0e0e;
}

#minimise_organisme_checklist .red .progresse .inner .percent {
  color: #a30f0f;
}

#minimise_organisme_checklist .red .progresse .inner .percent {
  text-shadow: 0 0 10px #7a0b0b;
}

#minimise_organisme_checklist .orange {
  margin-top: 15px;
}

#minimise_organisme_checklist .orange .progresse {
  border: 5px solid #f07c3e;
}

#minimise_organisme_checklist .orange .progresse {
  box-shadow: 0 0 20px #7e320a;
}

#minimise_organisme_checklist .orange .progresse .inner .water {
  top: 50%;
}

#minimise_organisme_checklist .orange .progresse .inner .water {
  background: rgba(240, 124, 62, 0.5);
}

#minimise_organisme_checklist .orange .progresse .inner .water {
  box-shadow: 0 0 20px #a0400c;
}

#minimise_organisme_checklist .orange .progresse .inner .percent {
  color: #a8430d;
}

#minimise_organisme_checklist .orange .progresse .inner .percent {
  text-shadow: 0 0 10px #7e320a;
}

#minimise_organisme_checklist #copyright {
  margin-top: 25px;
  background-color: transparent;
  font-size: 14px;
  color: #b3b3b3;
  text-align: center;
}

#minimise_organisme_checklist #copyright div {
  margin-bottom: 10px;
}

#minimise_organisme_checklist #copyright a,
#minimise_organisme_checklist #copyright a:link {
  color: #808080;
  text-decoration: none;
  border-bottom: 1px solid #808080;
  padding-bottom: 2px;
}

#minimise_organisme_checklist #copyright a:active {
  color: #b3b3b3;
}

#minimise_organisme_checklist #copyright a:hover {
  color: #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 4px;
}

#minimise_organisme_checklist .instructions {
  display: inline-block;
  margin: 5px 0;
  font-size: 16px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#memberships legend {
  position: relative;
}

#memberships legend .edit {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 5%;
}

#memberships fieldset .content {
  border: solid 1px red;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

#memberships fieldset .content .dates, #memberships fieldset .content .date_paiement, #memberships fieldset .content .method_paiement {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#memberships fieldset .content.green {
  border: solid 1px green !important;
}

#ajax_render_details {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}

#ajax_render_details .cotisation_content {
  width: 80vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
}

#ajax_render_details .cotisation_content .closed {
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
}

#course-wrapper .mandatory {
  border: solid 2px red;
  border-radius: 8px;
  position: relative;
}

#course-wrapper .mandatory:after {
  content: "Un de ces champs est obligatoire";
  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.8);
  padding: 2px 10px;
  display: block;
  width: 100%;
  color: white;
  top: 110%;
}

#course-wrapper .action_buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

#table_lvl .align-items-center {
  width: 100vw !important;
  overflow: scroll;
  overflow-y: hidden;
}

#stat .content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#stat .content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

#stat .content-table thead tr th {
  border: solid 1px;
}

#stat .content-table th {
  padding: 12px 15px;
  text-align: center;
}

#stat .content-table td {
  padding: 12px 15px;
}

#stat .content-table td {
  text-align: center;
}

#stat .content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

#stat .content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

#stat .content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

#stat .content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

#stat .content-table tfoot {
  font-size: .8rem;
}

.moutarde {
  color: #ffc107 !important;
}

.vert {
  color: #28a745 !important;
}

.red {
  color: #c82333 !important;
}

.pourpre {
  color: #8b0000 !important;
}

.brown {
  color: #93282a !important;
}

.blue {
  color: #4169E1 !important;
}

.blue_light {
  color: #97bfe3 !important;
}

.grey {
  color: #bfbfbf !important;
}

.orange {
  color: #D2691E !important;
}

.orange_dpm {
  color: #f8aa6a !important;
}

.blue_dpm {
  color: #59BDBD !important;
}

.red_strong {
  color: #D80A0A !important;
}

.yellow {
  color: #eec64d !important;
}

.green_dpm {
  color: #3cb371 !important;
}

.purple_dpm {
  color: #da70d6 !important;
}

body {
  background: rgba(200, 200, 200, 0.8);
}

a {
  text-decoration: none !important;
  color: #333333;
}

a:hover {
  text-decoration: none !important;
  color: #333333;
}

*, h1 {
  font-family: 'saira_semicondensedregular', sans-serif;
}

h2, h3, h4, h5, h6 {
  font-family: 'Economica', sans-serif;
}

h2 sup svg {
  width: 20px !important;
  margin-left: 5px;
}

.table_options,
#table_options {
  position: relative;
}

#config-wrapper .table_options ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

#config-wrapper .table_options ul li {
  margin: 5px 10px;
}

#table_options ul {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

table ul {
  padding: 0;
}

table svg {
  margin: initial !important;
}

#message_flash {
  position: absolute;
  left: calc(50% - 108px);
  top: 25%;
  z-index: 9;
}

#logo {
  width: 50px;
}

.hidden {
  display: none;
}

.i-big {
  font-size: 20px;
}

/*//modif bouton upload*/
#FakeInput {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#FakeInput input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  width: 50%;
  height: 100% !important;
}

#FakeInput span {
  display: inline-block;
  vertical-align: top;
}

#FakeInput span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  height: 25px;
  padding-left: 5px;
  border: 1px solid #333333;
  text-overflow: ellipsis;
  font: 100%/25px sans-serif;
  color: #666;
  border-radius: 3px;
}

#FakeInput span + span {
  margin-left: 5px;
  text-align: center;
  text-decoration: underline;
}

#FakeInput label {
  display: initial;
  width: 100%;
}

#FakeInput .new_label {
  text-decoration: none;
  cursor: pointer;
}

#FakeInput .uplaod_rules {
  width: 100%;
  font-size: 12px;
}

.toggle-group .btn {
  width: initial !important;
}

.valide {
  color: #28a745;
}

.proches {
  color: #FF8C00;
}

.urgence {
  color: #c82333;
}

#member-section .valide {
  color: #28a745;
}

#member-section .proches {
  color: #FF8C00;
}

#member-section .urgence {
  color: #c82333;
}

.fs-25 {
  font-size: 25px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.border-1 {
  border: solid 1px;
}

.border-2 {
  border: solid 2px;
}

.border-3 {
  border: solid 3px;
}

.border-4 {
  border: solid 4px;
}

.border-5 {
  border: solid 5px;
}

.border-dpm {
  border-color: #59BDBD !important;
}

.actions_elements_block {
  display: flex;
}

.actions_elements_block label select {
  font-size: 14px;
  padding: 0.5rem 0.75rem;
  margin-right: 10px;
}

#periode_essai {
  position: fixed;
  bottom: 0;
  right: 0;
  border: solid 1px red;
  text-transform: uppercase;
  padding: 5px 10px;
  background: white;
  z-index: 9;
}

#periode_essai a {
  color: red !important;
}

.video_documentation .title {
  color: #59BDBD;
  font-weight: bold;
  font-size: 1.3rem;
}

/*CHECKBOX*/
#organisme input[type="checkbox"] {
  position: relative;
  width: 60px !important;
  height: 20px !important;
  -webkit-appearance: none;
  background: #8b0000 !important;
  outline: none;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: .5s;
  margin: 0 5px;
  cursor: pointer;
}

#organisme input:checked[type="checkbox"] {
  background: #59BDBD !important;
}

#organisme input[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 30px !important;
  height: 20px !important;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: #fff !important;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: .5s;
}

#organisme input:checked[type="checkbox"]:before {
  left: 28px !important;
}

.invalid {
  color: red;
}

/*********************LOADER*********************/
#loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

#loader .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
}

#loader .content img {
  margin: auto;
}

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Color/Title/Font/link/List
2.0 Navigation
3.0 Header
4.0 Footer
5.0 Button
6.0 Footer
7.0 Pages
	 7.1 HOME
	 7.2 Family
	    7.2.1 Family list
	    7.2.3 Family details
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Color/Title/Font/link/List
--------------------------------------------------------------*/
@media (max-width: 991px) {
  /*-----------------------------------------------------------------------------------------------------------------

    ------------------- 1.0 Color/Title/Font/link/List

    ----------------------------------------------------------------------------------------------------------------- */
  #modal-outils-home {
    margin: 0 0;
    z-index: 999;
    background: #fff;
    position: absolute;
    text-align: center !important;
    border: solid 1px #999;
    padding: 10px 0;
    max-width: 720px;
  }
  #modal-outils-home img {
    width: 50% !important;
  }
  #modal-outils-home svg {
    font-size: 50px;
  }
  #modal-outils-home > div > div > div {
    height: 100px;
    padding: 0;
  }
  #modal-outils-home #statut-page {
    /*! margin-top: 10px!important; */
  }
  #modal-outils-home #identification {
    width: 40% !important;
    margin: 0 20px !important;
  }
  #modal-outils-home .hide {
    font-size: 50px;
  }
  #modal-outils-home #activation {
    margin-bottom: 20px;
    height: initial;
  }
  #member-template #modal-outils-home {
    padding: 10px;
  }
  #modal-outils-home .content {
    padding-right: 20px;
  }
  /*--------------------------------------MENU--------------------------------------*/
  #new_menu {
    margin-bottom: 50px;
  }
  #new_menu .navbar {
    padding: 0.5rem 1rem;
  }
  #new_menu .nav-link svg {
    width: auto;
    display: inherit;
    margin: initial;
    margin-bottom: 0;
  }
  #new_menu .nav-link {
    margin-bottom: 10px;
  }
  /*-----------------------------------------------------------------------------------------------------------------

    ------------------- MEMBRES

    ----------------------------------------------------------------------------------------------------------------- */
  .bouton-lat {
    top: 90px !important;
  }
  #member-template h1 {
    font-size: 18px;
  }
  #member-template sup {
    top: 0;
    font-size: 12px;
  }
  .block-adresse {
    font-size: 12px;
  }
  #show, #edit {
    font-size: 20px;
  }
  /*---------------------------------DETAILS MEMBRE---------------------------------*/
  #action-alerte .emails {
    color: #17a2b8;
  }
  #action-alerte .emails:hover {
    color: #fff;
  }
  #action-alerte .hover {
    top: -90px;
    left: 0;
    width: 100%;
    display: none;
  }
  #action-alerte .hover a {
    border-bottom: 1px solid #999 !important;
    padding: 10px;
  }
  #action-alerte .hover a:hover {
    background: #17a2b8;
    color: #fff;
  }
  #action-alerte .btn-group:hover .hover {
    display: block;
    position: absolute;
    bottom: 40px;
  }
  /*----------------------------------MEMBRE LISTE----------------------------------*/
  #member-section h1 {
    text-align: center !important;
  }
  /*-----------------------------------------------------------------------------------------------------------------

    ------------------- ANIMAL

    ----------------------------------------------------------------------------------------------------------------- */
  .tuile-animal #show, .tuile-animal #edit {
    font-size: 20px;
    margin: 0 10px;
  }
  /*-----------------------------------------------------------------------------------------------------------------

    ------------------- tableaux

    ----------------------------------------------------------------------------------------------------------------- */
  #table_alerte_wrapper {
    overflow: scroll;
  }
  .emails .btn-group {
    width: 100%;
  }
  /*-----------------------------------------------------------------------------------------------------------------

    ------------------- Présence

    ----------------------------------------------------------------------------------------------------------------- */
  .fc-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .fc-left div {
    width: 100%;
  }
  .fc-left div button:nth-child(3) {
    width: 65%;
  }
  .fc-right {
    width: 100%;
    margin-bottom: 10px;
  }
  .fc-right .fc-button-group {
    width: 96%;
  }
  .fc-right button {
    width: 33%;
    margin-bottom: 10px;
  }
  .fc-center h2 {
    text-transform: capitalize;
  }
}

.essai {
  color: #ffc107;
}

.valid {
  color: #28a745;
}

.readh {
  color: #c82333;
}

.cxl {
  color: #8b0000;
}

@media screen and (max-width: 767px) {
  #navbar .navbar-nav li iframe {
    display: none;
  }
  #navbarSupportedContent li a svg {
    width: 40px !important;
    margin: 0 !important;
    margin-right: 10px !important;
  }
  #navbarSupportedContent li a {
    display: flex !important;
  }
}

.container {
  background: #fff;
  padding-top: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px 2px #999;
}

#stat.container {
  border: solid #eec64d !important;
}

.resume-text,
#comment,
.detail-text,
.tuile-animal,
#animal-problems,
#section #tuiles .ok,
#member-section .members-list,
#accueil-creation .vignette {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: 2px !important;
  box-shadow: 1px 0 3px #999 !important;
  padding: 10px !important;
  border-left-width: 3px !important;
}

.descriptif {
  margin-top: 20px;
}

.er-contact {
  height: 81px;
}

#animal-zone, #member-zone {
  margin-top: 30px;
}

.tuile-animal {
  padding: 10px;
  margin-bottom: 10px;
}

.tablinks {
  cursor: pointer;
}

#very-small {
  font-size: 10px;
}

#small {
  font-size: 12px;
}

#retour {
  height: 100% !important;
}

#doc-pdf, #doc-word {
  font-size: 120px;
}

#titre {
  color: #59BDBD;
  font-weight: bold;
}

.resume-text {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.tuile-animal {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.detail-text {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px;
}

.assurance {
  color: #c82333;
}

.attention {
  color: #FF8C00;
}

.block-adresse {
  font-size: 17px;
  padding: 0;
}

#animal .assurance, #animal .proches, #animal .attention {
  font-weight: normal !important;
}

#animal .assurance i, #animal .proches i, #animal .attention i {
  font-weight: 900 !important;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

#bold {
  font-weight: bold;
}

form li {
  color: red;
  font-weight: bold;
}

/******** Form***************/
.container {
  border: solid #59BDBD;
  border-radius: 10px;
}

.container .top {
  margin-top: 50px;
}

.container .bottom {
  margin-bottom: 20px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #495057;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #495057;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #495057;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #495057;
}

label {
  display: block;
  font-size: 20px;
}

input {
  width: 100%;
}

textarea {
  height: 150px;
  padding: 10px;
  width: 100%;
}

select {
  width: 100%;
  padding: 0;
}

#animal_solo_images_file, #app_bundle_animal_type_images_file {
  height: 46px !important;
}

#member_solo_idfamily {
  width: auto;
}

h2 {
  font-size: 30px;
  font-weight: normal;
}

#bouton-row {
  margin-top: 30px;
}

.container {
  padding-top: 10px;
}

hr {
  margin-bottom: 4px;
}

#app_bundle_family_type_id_type {
  width: 100%;
}

.adherent #app_bundle_adherent_type_image_rights,
.adherent #app_bundle_adherent_type_job,
.adherent #app_bundle_adherent_type_categories,
#member_solo_idfamily {
  width: 100% !important;
}

.animal select {
  width: 100%;
}

.animal #app_bundle_animal_type_sterilized, #animal_solo_sterilized {
  /*   border: solid; */
  display: inline-flex;
}

.animal #app_bundle_animal_type_sterilized label,
#animal_solo_sterilized label,
.animal #app_bundle_animal_type_sterilized #app_bundle_animal_type_sterilized_0,
.animal #app_bundle_animal_type_sterilized #app_bundle_animal_type_sterilized_1,
#animal_solo_sterilized_0, #animal_solo_sterilized_1 {
  /*   border: red solid; */
  width: 50%;
  display: block;
}

#app_bundle_animal_type_sterilized_0, #app_bundle_animal_type_sterilized_1,
#animal_solo_sterilized_0, #animal_solo_sterilized_1 {
  margin: 0 10px 0 20px;
}

.animal #app_bundle_animal_type_other_vaccins {
  height: 104px;
}

.animal #image-holder img {
  width: 100%;
  border: solid white 1px;
}

.animal #image-holder {
  max-height: 200px;
  overflow: auto;
}

#app_bundle_family_type_familyStatus, #app_bundle_family_type_id_type {
  width: 100%;
  text-transform: capitalize;
}

.contact textarea {
  height: 70px !important;
  margin-bottom: 15px;
}

.commentaire textarea {
  height: 100px !important;
}

.thumb-image {
  width: 100%;
  padding: 10px 0;
}

#member_solo_save_and_add, #animal_solo_save_and_add {
  display: none;
}

#app_bundle_adherent_type_idfamily {
  border: none;
}

.big select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*******************FOND FORM FAMILY***********************/
.family {
  position: relative;
}

@media (max-width: 665px) {
  .family:before, .adherent:before {
    display: none;
  }
}

/*******************FOND FORM adherent***********************/
.adherent {
  position: relative;
}

#app_bundle_try_adherent_type_idfamily {
  border: none;
}

/*******************FOND FORM adherent try***********************/
.adherent-try {
  position: relative;
}

.adherent-try:before {
  content: "\F007";
  color: #59BDBD;
  color: rgba(89, 189, 189, 0.1) !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -312px;
}

/*******************FOND FORM ANIMAL***********************/
.animal {
  position: relative;
}

.death label {
  font-size: 12px !important;
}

/*********try*******************/
.animal-try {
  position: relative;
}

.animal-try:before {
  content: "\F1B0";
  color: #59BDBD;
  color: rgba(89, 189, 189, 0.1) !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -312px;
}

/*******************FOND FORM FAMILY TRY***********************/
.family-try {
  position: relative;
}

.family-try:before {
  content: "\F0C0";
  color: #59BDBD;
  color: rgba(89, 189, 189, 0.1) !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -312px;
}

/********************* section************/
.search .search-filtre input {
  font-size: 20px;
}

.search .alert-filtre button {
  font-size: 20px;
}

.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.search > div:first-child {
  width: 100% !important;
  margin-bottom: 10px;
}

.search > div:first-child .input-flat {
  margin: 0 !important;
}

.search > div:first-child .form-group {
  margin: 0;
}

#section #tuiles {
  padding: 10px;
}

#section a {
  color: initial;
}

#section #tuiles h3 {
  font-size: 20px;
}

#members-section #tuiles a {
  color: #333333;
}

#members-section #tuiles a:hover {
  text-decoration: none;
}

#section .icones, #section .rages {
  width: 100%;
  margin: 0;
  bottom: 5%;
  font-size: 25px;
}

#section #tuiles .ok {
  position: relative;
}

#section .valide {
  color: #28a745;
}

#section .affiche {
  position: relative;
  color: #333333;
}

#section a {
  text-decoration: none;
}

#section .card-body {
  position: relative;
  padding: 0;
  /*     padding-top: 0 */
}

#section .assurances:hover .hover, #section .adhesion:hover .hover, #section .status:hover .hover, #section .rages:hover .hover, #section .id-family:hover .hover {
  display: block;
}

#section .assurances .hover, #section .adhesion .hover, #section .status .hover, #section .rages .hover, #section .id-family .hover {
  border: solid 1px #59BDBD;
  display: none;
  background: #FFFFFF;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#section .hover h3, #section .hover h3 {
  font-size: 13px !important;
}

#section .text-hover {
  font-size: 11px;
}

#section #family-number {
  font-size: 20px;
}

#section .sous-titre {
  font-size: 13px;
}

#section #animal .details {
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-wrap: wrap;
}

#section #animal .details > div {
  display: flex;
  align-items: center;
  width: calc(100% / 6);
}

@media (max-width: 991.98px) {
  #section #animal .details > div {
    margin-top: 10px;
    width: calc(100% / 2);
  }
}

#section #animal .details > div > div {
  width: 100%;
}

#section #animal .details h3 {
  margin: 0;
  padding: 0;
}

/*!****************************general icons*************************************!*/
#perfect {
  color: #28a745;
}

#wrong {
  color: #dc3545;
}

#birth, #phone, #animal-template #user, #email {
  color: #4169E1;
}

#show, .show {
  color: #bfbfbf;
}

#edit {
  color: #D2691E;
}

#femelle, #male, #passeport, #identity, #categorie, #student, #activity, #job-case, #user-add, #user-link {
  color: #6E6e6e;
  margin-right: 5px;
}

#paw, #family {
  color: #59BDBD;
}

#no-docs, #steri-no {
  color: #D80A0A;
}

#yes-docs, #steri-yes, #valid {
  color: #28a745;
}

#post-it {
  color: #DAA520;
}

#try {
  color: #ffc107;
}

#redo {
  color: #c82333;
}

#soon, .soon {
  color: #FF8C00;
}

#delete, #canceled {
  color: #8B0000;
  font-size: 40px;
  padding-top: 10px;
}

#alerte {
  color: #c82333;
}

#activite #delete, #race #delete {
  font-size: 15px;
}

#statut-page {
  width: 20px;
  margin: 0 7px;
}

.fa-syringe {
  margin-right: 0 !important;
}

/**********************CREATION PAGE*********/
#accueil-creation .vignette {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 5px #59BDBD;
  border-radius: 10px;
  font-size: 30px;
  text-decoration: none;
  height: 150px;
  width: 100%;
  padding: 20px 10px;
  text-align: center;
}

#accueil-creation .vignette:hover {
  background: #59BDBD;
  color: white;
}

/********DOCS PAGE********/
/*******animal template*********/
#detail-animal ul li {
  width: auto;
  display: inline-flex;
}

#animal-template .liste-conducteur {
  padding: 0;
}

#animal-template .descriptif #detail-animal {
  position: relative;
}

#comment {
  position: relative;
}

#animal-template .descriptif #global-animal {
  position: relative;
}

#animal-template #school {
  position: relative;
}

#animal-template #school #show {
  width: 15px;
}

#animal-template #global-animal .row, #animal-template #detail-animal .col-12 {
  margin-bottom: 5px;
}

#animal-template ul {
  padding-left: 0;
}

#animal-template #school div {
  /*   border: solid; */
  padding-left: 15px;
}

#animal-template .liste-conducteur li {
  /*   border: solid; */
  list-style: none;
  width: 48%;
  display: inline-table;
}

#animal-template #passeport {
  width: 12px;
  margin-right: 8px;
  margin-left: 5px;
}

#animal-template .descriptif svg {
  width: 25px;
}

#animal-template .liste-conducteur a {
  padding-right: 5px;
}

#animal-template .descriptif .resume-text, #member-template .descriptif .resume-text {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

#animal-template .resume-text #myImg {
  max-height: 270px;
}

/* Style the Image Used to Trigger the Modal */
#myImg {
  /*border-radius: 5px;*/
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  box-shadow: 3px 3px 3px #999;
}

#myImg:hover {
  opacity: 0.7;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  box-shadow: none;
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  /*color: #ccc;*/
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 7%;
  right: 21%;
  color: #ffffff !important;
  font-size: 70px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

/**************************member template ***************************/
#member-template li {
  list-style: none;
}

#member-template ul {
  padding: 0;
}

#member-template svg {
  margin-right: 10px;
}

#member-template #small {
  font-size: 18px;
}

.member-category li {
  /*   border: solid; */
  list-style: none;
  width: 49%;
  display: inline-table;
}

#member-template #global-details .resume-text {
  position: relative;
}

#member-template #category-details .resume-text {
  position: relative;
}

/**************************member list ***************************/
#member-section .members-list {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px 0;
  font-size: 14px;
  /*   color: white; */
}

#member-section #show, #member-section #edit {
  font-size: 20px;
}

/***************date form******************/
#app_bundle_animal_type_rag_date_month,
#app_bundle_animal_type_rag_date_day,
#app_bundle_animal_type_rag_date_year,
#family_solo_first_adh_date_month,
#family_solo_first_adh_date_day,
#family_solo_first_adh_date_year,
#member_solo_dob_month,
#member_solo_dob_day,
#member_solo_dob_year,
#family_solo_end_adh_date_month,
#family_solo_end_adh_date_day,
#family_solo_end_adh_date_year,
#family_solo_insuranceDate_month,
#family_solo_insuranceDate_day,
#family_solo_insuranceDate_year,
#app_bundle_animal_type_dob_day,
#app_bundle_animal_type_dob_month,
#app_bundle_animal_type_dob_year,
#app_bundle_adherent_type_dob_day,
#app_bundle_adherent_type_dob_month,
#app_bundle_adherent_type_dob_year,
#app_bundle_family_type_end_adh_date_day,
#app_bundle_family_type_end_adh_date_month,
#app_bundle_family_type_end_adh_date_year,
#app_bundle_family_type_first_adh_date_day,
#app_bundle_family_type_first_adh_date_month,
#app_bundle_family_type_first_adh_date_year,
#app_bundle_family_type_insuranceDate_day,
#app_bundle_family_type_insuranceDate_month,
#app_bundle_family_type_insuranceDate_year,
#animal_solo_dob_day,
#animal_solo_dob_month,
#animal_solo_dob_year,
#animal_solo_rag_date_day,
#animal_solo_rag_date_month,
#animal_solo_rag_date_year,
#animal_solo_dod_day,
#animal_solo_dod_month,
#animal_solo_dod_year,
#app_bundle_animal_type_dod_day,
#app_bundle_animal_type_dod_month,
#app_bundle_animal_type_dod_year,
#app_bundle_animal_type_dateOtherVaccin_day,
#app_bundle_animal_type_dateOtherVaccin_month,
#app_bundle_animal_type_dateOtherVaccin_year,
#animal_solo_dateOtherVaccin_day,
#animal_solo_dateOtherVaccin_month,
#animal_solo_dateOtherVaccin_year,
#app_bundle_alerte_type_alerteDate_day,
#app_bundle_alerte_type_alerteDate_month,
#app_bundle_alerte_type_alerteDate_year {
  width: 32.2% !important;
}

/*********************NAV DROITE**************************/
.bouton-lat {
  display: inline-block;
  position: fixed;
  right: 0;
  top: 110px;
  z-index: 100;
  background: #f8f9fa;
  box-shadow: 0 0 5px #999;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.bouton-lat svg {
  font-size: 30px;
  color: #008080;
  margin: 10px 0;
  background: #f8f9fa;
}

.bouton-lat .btn, .button {
  border: none;
  background-color: #f8f9fa;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: auto;
}

.bouton-lat .sidebar {
  width: 0;
  z-index: 100;
  margin-left: 10px;
}

/********************INDEX ******************/
/****************ACCEUIL*******************/
/******************DOCUMENTS***************/
#documents img {
  width: 100%;
  padding: 10px 0;
}

#app_bundle_documents_imageFile, #add_document_solo_imageFile {
  padding: 0 !important;
}

#detail-doc {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

#documents td, td {
  position: relative;
}

/******************COMMENT ZONE************/
#comment {
  border: solid 5px #59BDBD;
  border-radius: 10px;
  padding: 10px 0;
  height: 100%;
}

#member-template #comment .description, #animal-template #comment .description {
  /*border: solid 1px $dpm_color;*/
  height: 190px;
  overflow: auto;
  border-radius: 5px;
  padding: 5px 0;
  word-wrap: break-word;
}

#comment .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #495057;
}

#comment .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #495057;
}

#comment .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #495057;
}

#comment .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #495057;
}

#comment textarea {
  height: 100px;
  padding: 10px;
  width: 100%;
}

/*****************************config*******************************************/
#config-wrapper .container {
  border-radius: 10px;
}

#config-wrapper select, #config-wrapper input, #config-wrapper label {
  display: initial;
}

#config-wrapper #activite .search label, #config-wrapper #race .search label {
  display: block !important;
  margin: 20px auto;
}

#power-on {
  color: #c82333;
  font-size: 25px;
}

#user_activity_table #userRole #edit {
  float: left;
  font-size: 25px;
}

input[type="radio"] {
  -webkit-appearance: radio !important;
  border-radius: 0;
}

input, textarea, button {
  /*-webkit-appearance: none;*/
  -webkit-font-smoothing: antialiased;
  resize: none;
}

input[type="checkbox"] {
  background-color: #eee;
  width: 20px;
  height: 20px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.input-flat {
  width: 100%;
}

.cRetour {
  border-radius: 3px;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  z-index: 99999;
  transition: all ease-in 0.2s;
  position: fixed;
  cursor: pointer;
  bottom: 1em;
  right: 20px;
  display: none;
}

.cRetour:hover {
  background: black;
  transition: all ease-in 0.2s;
}

#arrow, #phone {
  font-size: 15px;
}

#config-wrapper ul {
  width: 100%;
}

/*******************USER ROLE********************/
#power-off {
  color: #c82333;
  font-size: 25px;
}

#warning {
  color: #FFA500;
  font-size: 25px;
}

#power-on {
  color: #28a745;
  font-size: 25px;
}

#userRole #edit {
  float: left;
  font-size: 25px;
}

#userRole {
  margin-bottom: 50px;
}

/*******************EXPORTS***********************/
#export-choice a:not(.link-courses), #export-choice .btn-secondary {
  border-radius: 2px !important;
  box-shadow: 3px 0 3px #999 !important;
  border-left: 3px solid #eec64d !important;
  padding: 10px;
  color: #565656;
  text-align: center !important;
  font-size: 30px;
  background: #fff;
  width: 100%;
}

#export-choice {
  padding: 20px;
}

#export-choice a svg {
  font-size: 30px;
  color: #eec64d;
}

#export-choice a:hover svg, #export-choice .active svg {
  color: #565656;
}

/******************ALERTE**********************/
#alert-wrapper .container {
  border: solid #c82333 3px;
  border-radius: 10px;
  margin: 50px auto 20px;
  padding-top: 10px;
}

/***********************************************************************************************************
modals
 */
/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /*$txt_color_dark w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.closeModal {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeModal:hover,
.closeModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* page saisi commentaire*/
#comment-zone textarea {
  width: 100%;
}

/*************OUTILS******************************/
/*************OUTILS******************************/
#outils .on-off {
  padding: 0 10px;
}

#outils .on-off a {
  line-height: 2;
}

#outils {
  position: relative;
}

#outils #identification {
  width: 40%;
  display: block;
  margin-left: auto;
}

#outils #over-outils {
  width: 100%;
  position: absolute;
  top: 55%;
  right: 0;
}

#outils #identification:hover #over-outils ul {
  display: block;
}

#outils #over-outils ul {
  list-style: none;
  margin: auto;
  width: 80%;
  box-shadow: 0 0 5px #999;
  background: #fff;
  z-index: 900;
  display: none;
}

#outils #over-outils li {
  width: 33%;
  float: left;
  text-align: center !important;
}

#outils #over-outils label {
  display: block;
  text-align: center;
}

#outils #over-outils .id-number, #outils #over-outils .on-off {
  width: 100%;
}

#outils #over-outils li img {
  width: 50%;
  display: block;
  margin: 10px auto;
}

#outils #over-outils li svg {
  font-size: 30px;
  text-align: center !important;
}

#outils #over-outils li a {
  display: block;
  text-align: center;
  width: 100%;
  margin: 0 0;
  padding: 0;
  height: 40px;
}

#family-desc #outils .family-suppresion:hover .suppresion-hover {
  display: flex;
}

#family-desc #outils .family-suppresion .suppresion-hover {
  display: none;
}

#family-desc #outils .family-suppresion .suppresion-hover a {
  line-height: 2.2;
  margin-top: 5px;
}

#family-desc #outils .family-suppresion .suppresion-hover a:hover {
  line-height: 2.2;
  color: #fff;
}

/***********************************************************************/
/*    email */
/***************************/
#create-email input, #create-email textarea, #create-email select {
  width: 100%;
}

#email-wrapper .container {
  border: solid #97bfe3 3px;
  border-radius: 10px;
  margin: 50px auto 20px;
  padding-top: 10px;
}

#email-send select, #email-send textarea {
  width: 100% !important;
}

#email-send input {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0;
}

/***********************************************************************/
/*    formulaires */
/***************************/
.comment_form {
  border: none;
  background: transparent;
  width: 100% !important;
  resize: none;
}

#form_required input {
  width: 100%;
}

.preview_front_box {
  box-shadow: 0 0 10px #999;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.preview_front_box .preview_box {
  background: #fff;
  display: block;
  width: 90vw;
  margin: auto;
}

.preview_front_box img {
  padding: 0;
  margin: 0;
}

#documents .container {
  position: relative;
}

#close_preview {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: white;
}

#close_preview:hover {
  background: #c82333;
  color: #fff;
}

div.dts div.dataTables_scrollBody {
  background: none;
}

.dataTables_wrapper {
  overflow-x: auto;
}

.dataTables_wrapper input,
.dataTables_wrapper label,
.dataTables_wrapper select {
  width: initial;
  font-size: initial;
}

.dataTables_wrapper div.dt-buttons {
  float: right;
}

.dataTables_wrapper button.dt-button,
.dataTables_wrapper div.dt-button,
.dataTables_wrapper a.dt-button {
  margin-right: 0;
}

#table_alerte_filter {
  width: 100%;
}

#table_alerte_filter input {
  width: 74%;
}

/***********************************************************************/
/*    SEARCH PAGE */
/***************************/
#search_result a:hover {
  text-decoration: none;
  color: inherit;
}

/***********************************************************************/
/*    presences */
/***************************/
#course-wrapper .container {
  border: solid #f8aa6a;
}

#course-wrapper a:hover {
  text-decoration: none;
}

.liste_moniteurs li {
  list-style: none;
}

.fc-center h2 {
  text-transform: capitalize;
}

#course-wrapper .toggle-group label {
  margin: 0 !important;
}

#article_filter_mobile {
  position: fixed;
  left: 0;
  bottom: 1vh;
  z-index: 99999;
  width: 93%;
  margin: 0 2.75vw;
  background: #fff;
  border-radius: 20px;
  padding: 5px 5px 0;
  box-shadow: 0 3px 50px #0000001C;
}

#article_filter_mobile .panel-heading a {
  width: 93%;
}

#article_filter_mobile .panel-default {
  margin: 10px;
}

#article_filter_mobile .category_title {
  line-height: 3;
  font-weight: bold;
  position: relative;
  color: #a7a8a9;
}

#article_filter_mobile ul {
  list-style: none;
}

#article_filter_mobile .press_title {
  font-size: 16px;
  line-height: 3;
  color: #003b5c;
}

#article_filter_mobile .categorie_titre ul, #article_filter_mobile .sub_filtre {
  padding-left: 0;
  margin-top: 10px;
}

#article_filter_mobile {
  /*   border: solid;  */
  box-shadow: 0 0 10px #999;
  cursor: pointer;
}

.family-suppresion:hover {
  position: relative;
}

.family-suppresion:hover .rgpd-select {
  display: block !important;
  position: absolute;
  z-index: 9;
  background: white;
  padding: 10px;
  box-shadow: 0 0 1px #999;
}

#export-views #table_responsive_wrapper {
  width: 100%;
}

#quick_add_form {
  position: fixed;
  top: 0;
  bottom: 0;
  border: solid;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}

#quick_add_form .close {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
}

#quick_add_form .close i {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}

#quick_add_form .content {
  background: white;
  width: 90vw;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991.98px) {
  #quick_add_form .content {
    top: 0;
    bottom: 0;
    transform: none;
    left: 0;
    right: 0;
    width: 100%;
    overflow: scroll;
  }
}

#quick_add_form .content .title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}

#quick_add_form .content .form {
  display: flex;
}

@media (max-width: 991.98px) {
  #quick_add_form .content .form {
    flex-wrap: wrap;
  }
}

#quick_add_form .content .form .select {
  width: 32%;
  margin: 1%;
}

@media (max-width: 991.98px) {
  #quick_add_form .content .form .select {
    width: 100%;
  }
}

#quick_add_form .content .submit {
  text-align: right;
  margin-top: 20px;
}

#compta-wrapper .container {
  max-width: unset;
  min-height: 100vh;
}

.creation_form .discount_choice {
  display: flex;
  align-items: flex-end;
}

.creation_form .discount_choice .form-group {
  margin: 0;
  width: 100%;
  height: 33.5px;
}

.creation_form .discount_choice .form-group .checkbox {
  height: 100%;
}

.creation_form .discount_choice .form-group label {
  margin: 0;
  display: flex;
  align-items: center;
  height: 100% !important;
}

.creation_form .discount_choice .form-group label .toggle {
  width: 100% !important;
  height: 100% !important;
}

.creation_form .subs_details .form-group {
  margin: 0;
}

.creation_form .reduction_type .checkbox label {
  margin: 0;
}

.creation_form .reduction_type .checkbox label .toggle {
  width: 98% !important;
  height: 100% !important;
}

#menu-sidebar.project_menu .hc-offcanvas-nav a {
  border-bottom: 1px solid #da70d6;
}

#menu-sidebar.project_menu .hc-offcanvas-nav .nav-item {
  border-bottom: 1px solid #da70d6;
}

#project-container #form_absolute.creation_form .content,
#project-container #form_absolute.creation_form_doc .content,
#project-container #form_absolute_report.creation_form_report .content {
  border: solid #da70d6;
}

#project-container #form_absolute_button {
  right: 20px;
}

#project-container .tab h2::after {
  background: none repeat scroll 0 0 #da70d6;
  height: 3px;
}

#project-container > .container, #project-container .container.project {
  border: solid #da70d6 1px !important;
  position: relative;
  max-width: unset;
}

#project-container .home_header {
  border-bottom: solid 1px;
  padding-bottom: 10px;
}

@media (max-width: 991.98px) {
  #project-container .home_header > div {
    margin: 5px 5px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 21%;
    justify-content: center;
    text-align: center;
    padding: 5px;
  }
}

#project-container .tab_content {
  display: flex;
  flex-wrap: wrap;
}

#project-container .card {
  border-left: solid 3px #da70d6;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  width: 32%;
  margin-right: 1.3%;
  padding: 10px;
}

@media (max-width: 991.98px) {
  #project-container .card {
    width: 100%;
    margin-right: 0%;
  }
}

#project-container .card > .top {
  display: flex;
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #da70d6;
}

#project-container .card > .top > div {
  width: 50%;
}

#project-container .card .top .right {
  display: flex;
  justify-content: flex-end;
}

#project-container .card .top .right div {
  margin-left: 10px;
}

#project-container .card .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#project-container .card .content .top {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  margin-bottom: 10px;
}

#project-container .card .content .right {
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
}

#project-container .card .content .left {
  border: solid 1px #da70d6;
  width: 48%;
  padding: 5px;
}

#project-container .card .content .left .text-center {
  font-weight: bold;
  margin-bottom: 10px;
}

#project-container .card .content .middle {
  border: solid 1px #da70d6;
  width: 48%;
  padding: 5px;
}

#project-container .card .content .middle .text-center {
  font-weight: bold;
  margin-bottom: 10px;
}

#project-container .card .content ul {
  width: 100%;
}

#project-container .card .content ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}

#project-container .add_form_doc_button {
  right: 160px !important;
}

#project-container #project_details #project_quick_action .delete_report {
  margin-left: 15px;
}

#project-container #project_details .infos {
  display: flex;
}

@media (max-width: 991.98px) {
  #project-container #project_details .infos {
    flex-wrap: wrap;
    justify-content: center;
  }
}

#project-container #project_details .infos > div {
  margin: 0 5px;
  display: flex;
  align-items: center;
  border: solid 1px;
  border-radius: 10px;
  width: 22%;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

@media (max-width: 991.98px) {
  #project-container #project_details .infos > div {
    margin-bottom: 5px;
    width: 30%;
  }
}

#project-container #project_details section {
  border-bottom: solid 1px #da70d6;
  padding: 20px 0 10px;
}

#project-container #project_details .description .card {
  width: 31%;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  #project-container #project_details .description .card {
    width: 45%;
  }
}

#project-container #project_details .description .card .bottom {
  margin: 0;
}

#project-container #project_details .legende {
  position: absolute;
  top: 25px;
  left: 50%;
}

@media (max-width: 991.98px) {
  #project-container #project_details .legende {
    left: unset;
    right: 0;
  }
}

#project-container #project_details .legende .content {
  position: absolute;
  right: 50%;
  width: 250px;
  display: none;
}

#project-container #project_details .legende .content > div {
  position: relative;
  padding-left: 12px;
  font-size: 12px;
}

#project-container #project_details .legende .content > div:before {
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#project-container #project_details .legende .dead:before {
  background: red;
}

#project-container #project_details .legende .encours:before {
  background: grey;
}

#project-container #project_details .legende .passed:before {
  background: green;
}

#project-container #project_details .legende .proche:before {
  background: orange;
}

#project-container #project_details .legende .help_notice {
  border: 1px #da70d6 solid;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #da70d6;
  color: white;
  cursor: default;
  padding: 0;
}

#project-container #project_details .legende:hover .content {
  display: block !important;
  border: solid 1px;
  padding: 10px;
  background: white;
  z-index: 99;
}

#project-container #tasks, #project-container #calendar1 {
  height: 100%;
}

/**********************************************************
OVERRIDES
 */
.select2 {
  width: 100% !important;
}

.select2-selection {
  min-height: calc(1.5em + 0.75rem) !important;
  display: flex !important;
  align-items: center !important;
}

.select2-container--default .select2-selection--single {
  border-color: #ced4da;
}

/**********************************************************
GENERAL
 */
body {
  color: #333333;
  padding-top: 60px;
  /** A partir de **/
  /** En dessous de **/
}

body .container {
  margin: 5vh auto;
}

/**********************************************************
HEADER
 */
#search-btn {
  position: relative;
  cursor: default;
}

@media (max-width: 991.98px) {
  #search-btn {
    position: absolute;
    right: 50px;
    top: 25px;
  }
}

#search-btn .btn {
  position: absolute;
  width: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  transition: all 1s;
  right: 0;
  cursor: default;
  height: 30px;
}

#search-btn .btn i.search_icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  height: 100%;
  line-height: 2;
}

#search-btn .btn input {
  padding-left: 10px;
  margin-right: 10px;
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 1s;
}

#search-btn .btn.open {
  width: 90vw;
  min-height: 53px;
  transition: all 1s;
  background: #17a2b8;
}

@media (max-width: 991.98px) {
  #search-btn .btn.open {
    margin-right: -10vw;
    z-index: 999999;
  }
}

#search-btn .btn.open input {
  opacity: 1;
  height: 40px;
  width: 97%;
  transition: all 1s;
}

@media (max-width: 991.98px) {
  #search-btn .btn.open input {
    width: 94%;
  }
}

#search-btn .btn.open i.search_icon {
  width: 2%;
  margin-top: 0;
  position: relative;
  line-height: 3;
  color: white;
}

#search-btn #search_result {
  background: #fff;
  padding: 30px;
  width: 96.8%;
  margin-right: 2.9%;
  color: #333333;
  overflow-y: auto;
  max-height: 93vh;
}

@media (max-width: 991.98px) {
  #search-btn #search_result {
    width: 93.5%;
    margin-right: 5.6%;
  }
}

#search-btn #search_result h2 {
  color: #333333;
}

#search-btn #search_result #families .border {
  border-radius: 5px;
  box-shadow: 3px 0 3px #999 !important;
  padding: 5px;
}

#search-btn #search_result_back {
  height: 100vh;
  width: 100vw;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -9;
  background: rgba(0, 0, 0, 0.5);
}

body.beta_mode {
  padding-top: 100px;
}

body.beta_mode .message_beta {
  padding: 5px;
  white-space: nowrap;
  background: white;
  text-align: center;
  overflow: hidden;
}

body.beta_mode .message_beta a {
  color: #046faa;
  text-decoration: underline !important;
}

.messagedefilant {
  -moz-animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
  animation: marquee 30s linear infinite;
}

@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/**********************************************************
MENU
 */
/*********************************************
OVERRIDE DE HCOFFCANVAS
 */
.hc-offcanvas-nav .nav-close a:hover {
  color: #FFFFFF;
}

header {
  display: flex;
  height: 80px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 0;
  transition: all 200ms linear;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
}

header .logo {
  max-width: 100px;
  margin-left: 1%;
}

@media (max-width: 991.98px) {
  header .logo {
    width: 15%;
    margin-left: 20px;
  }
}

header .logo img {
  max-width: 200px;
  max-height: 65px;
  height: 100%;
}

header #search-btn {
  margin-right: 1%;
  width: 5%;
  padding: 0 0 0 6px;
}

@media (max-width: 991.98px) {
  header #search-btn {
    position: absolute;
    right: 50px;
    top: 25px;
  }
}

header #menu-site {
  width: 90%;
  justify-content: space-between;
}

header #menu-site > ul {
  width: 100% !important;
  display: flex;
  justify-content: space-evenly;
}

header #menu-site ul {
  list-style: none;
  padding: 0;
}

header #menu-site > ul > li {
  width: 11.1111111111%;
  position: relative;
}

#menu-site > ul > li > a {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

#menu-site > ul > li > a span {
  text-align: center;
  width: 100%;
}

.menu_link img {
  /*   border: solid; */
  width: 35px;
  margin: 0 auto;
}

#menu-site > ul > li > a i {
  margin: auto;
  width: 100%;
  text-align: center;
}

#menu-site ul li ul {
  position: absolute;
  padding: 10px;
  font-size: 13px;
  letter-spacing: 1px;
  background: #fcfaff;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  display: none;
}

#menu-site > ul > li i {
  font-size: 20px;
}

#menu-site ul li:hover ul {
  display: block;
}

#menu-site ul li ul li a {
  padding: 3px 15px;
  margin: 5px 0;
  border-radius: 2px;
}

#menu-site ul li ul li a:hover {
  margin: 5px 0;
  background: rgba(129, 103, 169, 0.6);
  width: 100%;
  color: #fff;
}

#menu-site ul li ul li a i {
  padding: 0;
  margin: 5px 0;
}

#menu-site a:hover {
  text-decoration: none !important;
  cursor: pointer;
}

#menu-site .sub-item .right-select:hover .right-submenu {
  display: block !important;
}

#menu-site .sub-item .right-submenu {
  position: absolute;
  left: 100%;
  top: 0;
  display: none;
}

#menu-site .right-select {
  position: relative;
}

#menu-site .right-select:after {
  content: '\f0da';
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 10px;
  top: 12px;
  font-weight: 900;
}

#menu-site .sub-item {
  z-index: 9999;
}

.hc-offcanvas-nav {
  z-index: 1 !important;
}

@media (max-width: 992px) {
  .hc-offcanvas-nav.nav-open {
    z-index: 9999 !important;
  }
}

@media (min-width: 1800px) {
  #menu-site ul li ul {
    width: 100%;
  }
}

#menu-site .menu_link .construction {
  color: red;
  text-align: center;
  background: white;
  width: 100%;
  margin-bottom: 12px;
}

#menu-site .menu_link:hover .construction:after {
  content: 'En construction';
  position: absolute;
  display: block;
  border: solid 1px;
  background: white;
  padding: 10px 0;
  top: 100%;
  left: 0;
  right: 0;
}

@media (max-width: 991.98px) {
  nav a img {
    display: none !important;
  }
}

nav.nav-open a {
  color: initial !important;
}

.hc-offcanvas-nav.nav-open[class*="hc-nav-"] div.nav-container,
.hc-offcanvas-nav.nav-levels-overlap ul li.level-open > .nav-wrapper {
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

#menu-site .nav-item {
  min-width: 104px;
}

.hc-nav-trigger {
  right: 20px;
}

.hc-offcanvas-nav .nav-container, .hc-offcanvas-nav .nav-wrapper, .hc-offcanvas-nav ul {
  background: #ffffff;
  color: #333333;
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a, .hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a,
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a:hover, .hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a:hover,
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open, .hc-offcanvas-nav.nav-levels-none .nav-container li.level-open {
  background: #fcfaff;
}

.hc-offcanvas-nav li.nav-close a, .hc-offcanvas-nav li.nav-back a {
  background: #ffffff;
  border-top: 1px solid #295887;
  border-bottom: 1px solid #295887;
}

.hc-offcanvas-nav a,
.hc-offcanvas-nav a, .hc-offcanvas-nav .nav-item {
  color: #333333;
}

.hc-offcanvas-nav li.nav-close span::before,
.hc-offcanvas-nav li.nav-close span::after {
  border-top: 2px solid #333333;
  border-left: 2px solid #333333;
}

.hc-offcanvas-nav:not(.touch-device) a:hover {
  background: #fcfaff;
}

.hc-offcanvas-nav li.nav-close a:hover, .hc-offcanvas-nav li.nav-back a:hover {
  background: #fcfaff;
  color: #333333;
}

.hc-offcanvas-nav span.nav-next::before, .hc-offcanvas-nav li.nav-back span::before {
  border-top: 2px solid #333333;
  border-left: 2px solid #333333;
}

.hc-offcanvas-nav li.nav-parent .nav-item {
  padding-right: 0;
}

.hc-offcanvas-nav .sub-item li {
  padding: 0;
  border: none;
}

/**********************************************************
FORMULAIRE
 */
#form_absolute_button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cotisation_steps #form_absolute_button {
  top: 40px;
}

#form_absolute.creation_form,
#form_absolute.creation_form_doc,
#form_absolute_report.creation_form_report {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

#form_absolute.creation_form .content,
#form_absolute.creation_form_doc .content,
#form_absolute_report.creation_form_report .content {
  position: absolute;
  width: 90vw !important;
  max-width: 100%;
  background: white;
  padding: 10px;
  left: 50%;
  border: solid #59BDBD;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 95vh;
}

#form_absolute.creation_form .content .titre,
#form_absolute.creation_form_doc .content .titre,
#form_absolute_report.creation_form_report .content .titre {
  text-align: center;
  margin-top: 20px;
  font-size: 22px;
}

#form_absolute.creation_form .content .close,
#form_absolute.creation_form_doc .content .close,
#form_absolute_report.creation_form_report .content .close {
  color: #333333 !important;
  font-size: 18px;
  opacity: 1;
  right: 10px;
  top: 10px;
  z-index: 9999;
}

/**********************************************************
TABLE
 */
table tr th {
  font-weight: normal;
}

/*********************************************
    ETC...
 */
.croise .select2 {
  width: 100% !important;
}

/*********************************************
    CALENDAR...
 */
.fc-content {
  padding-left: 10px;
}

#cke_notifications_area_email_main_message,
.cke_notifications_area {
  display: none !important;
}
