#form-login {
  h1 {
    display: block;
  }

  #titre {
    margin-top: 5%;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Overpass', cursive;
    font-size: 90px;
    color: #525252
  }

  .login-container {
    padding: 30px 30px 10px;
    border-radius: 10px;
    width: 75% !important;
    background-color: #ffffff;
    margin: 60px auto 60px auto;
    box-shadow: 0 2px 30px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    font-size: 12px;

    h1 {
      text-align: center;
      font-weight: normal;

      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }

    input[type=submit] {
      width: 100%;
      display: block;
      margin: 10px 0;
      position: relative;
      padding: 2%;
    }

    input[type=text], input[type=password] {
      //height: 44px;
      //font-size: 20px;
      width: 100%;
      margin-bottom: 10px;
      -webkit-appearance: none;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-top: 1px solid #c0c0c0;
      /* border-radius: 2px; */
      padding: 0 8px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
    }

    input[type=text]:hover, input[type=password]:hover {
      border: 1px solid #b9b9b9;
      border-top: 1px solid #a0a0a0;
      -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }
  }

  .login-submit {
    border: 0;
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    background-color: $dpm_color;
    border-radius: 10px;
    padding: 17px 0;
    cursor: pointer;
    font-size: 20px;

    a {
      text-decoration: none;
      color: #666;
      font-weight: 400;
      text-align: center;
      display: inline-block;
      opacity: 0.6;
      transition: opacity ease 0.5s;
    }
  }

  .login-submit:hover {
    border: 0;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }

  .login-help {
    font-size: 12px;
  }

  #logo-ok {
    margin-top: 1%;
    width: 10%;
    @include media-breakpoint-down(lg) {
      width: 15%;
    };
    @include media-breakpoint-down(sm) {
      width: 20%;
    }
  }

  #options {
    width: 80%;
    /*si a gauche*/
    margin-left: 15%;
  }

  .bg-id {
    background-image: url('../../images/backgroundweb2.png');
    background-repeat: no-repeat;
    background-size: 63%;
    background-position: center;
    max-width: 1200px;
    margin: auto;
  }

  .bg-id img {
    width: 65%;
    margin-top: 60px;
  }
}
//.fos_user_registration_register{
//  padding: 30px;
//  border-radius: 10px;
//  max-width: 350px;
//  width: 100% !important;
//  background-color: transparent;
//  margin: 50px auto;
//  box-shadow: 0 2px 30px 10px rgba(0, 0, 0, 0.3);
//  overflow: hidden;
//  font-family: 'Marvel', sans-serif;
//  font-size: 20px;
//}
//
//#fos_user_registration_form input[type=text], input[type=password], input[type=email],#fos_user_registration_form_username {
//  height: 44px;
//  font-size: 20px;
//  width: 100%;
//  margin-bottom: 10px;
//  -webkit-appearance: none;
//  background: #fff;
//  border: 1px solid #d9d9d9;
//  border-top: 1px solid #c0c0c0;
//  /* border-radius: 2px; */
//  padding: 0 8px;
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//}
//
//#fos_user_registration_form input[type=text]:hover, input[type=password]:hover {
//  border: 1px solid #b9b9b9;
//  border-top: 1px solid #a0a0a0;
//  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
//  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
//  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
//}
//#fos_user_registration_form a {
//  text-decoration: none;
//  color: #666;
//  font-weight: 400;
//  text-align: center;
//  display: inline-block;
//  opacity: 0.6;
//  transition: opacity ease 0.5s;
//}
//
//.fos_user_registration_register input[type=submit] {
//  /* border: 1px solid #3079ed; */
//  border: 0;
//  color: #fff;
//  text-shadow: 0 1px rgba(0,0,0,0.1);
//  background-color: $dpm_color;
//  border-radius: 10px;
//  padding: 17px 0;
//  cursor: pointer;
//  font-size: 20px;
//  width: 100%
//  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
//}