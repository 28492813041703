.hc-nav-trigger{
  right: 20px;
}
.hc-offcanvas-nav .nav-container, .hc-offcanvas-nav .nav-wrapper, .hc-offcanvas-nav ul{
  background: #ffffff;
  color: $txt_color_dark;
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a, .hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a,
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a:hover, .hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a:hover,
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open, .hc-offcanvas-nav.nav-levels-none .nav-container li.level-open{
  background: #fcfaff;
}
.hc-offcanvas-nav li.nav-close a, .hc-offcanvas-nav li.nav-back a{
  background: #ffffff;
  border-top: 1px solid #295887;
  border-bottom: 1px solid #295887;
}
.hc-offcanvas-nav a,
.hc-offcanvas-nav a, .hc-offcanvas-nav .nav-item{
  color: $txt_color_dark;
}
.hc-offcanvas-nav li.nav-close span::before,
.hc-offcanvas-nav li.nav-close span::after {
  border-top: 2px solid $txt_color_dark;
  border-left: 2px solid $txt_color_dark;
}
.hc-offcanvas-nav:not(.touch-device) a:hover{
  background: #fcfaff;
}

.hc-offcanvas-nav li.nav-close a:hover, .hc-offcanvas-nav li.nav-back a:hover {
  background: #fcfaff;
  color: $txt_color_dark;
}
.hc-offcanvas-nav span.nav-next::before, .hc-offcanvas-nav li.nav-back span::before{

  border-top: 2px solid $txt_color_dark;
  border-left: 2px solid $txt_color_dark;
}
.hc-offcanvas-nav li.nav-parent .nav-item{
  padding-right: 0;
}
.hc-offcanvas-nav .sub-item li{
  padding: 0;
  border: none
}