#course-wrapper {

  .action_buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

}
#table_lvl .align-items-center{
  width: 100vw!important;;
  overflow: scroll;
  overflow-y: hidden;
}

#stat {
  .content-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    thead {
      tr {
        background-color: #009879;
        color: #ffffff;
        text-align: left;
        font-weight: bold;

        th {
          border: solid 1px;
        }
      }
    }

    th {
      padding: 12px 15px;
      text-align: center;
    }

    td {
      padding: 12px 15px;

      &:not(.content-table
			td:first-child) {
        text-align: center;

      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #dddddd;

        &:nth-of-type(even) {
          background-color: #f3f3f3;
        }

        &:last-of-type {
          border-bottom: 2px solid #009879;
        }
      }

      tr.active-row {
        font-weight: bold;
        color: #009879;
      }
    }

    tfoot {
      font-size: .8rem;
    }

  }
}