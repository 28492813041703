.form-group.form-control-width-auto{
  .form-control{
    //width: auto;
  }
}
.form-group{
  margin-bottom: 0.5rem;
}
.form-group-display-inline-block{
  >div{
    >div {
      display: inline-block;
    }
  }
}


.compulsory:after{
  content: ' *';
  color: $errorColor;
}
.mandatory_form:after{
  content: "*";
  color: $errorColorDpm;
  margin-left: 5px;
}

.select2-container--open{
  z-index: 9999999;
}