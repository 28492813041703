.moutarde{
    color: #ffc107!important;
}
.vert{
    color: #28a745!important;
}
.red{
    color: #c82333!important;
}
.pourpre{
    color: #8b0000!important;
}
.brown{
    color: #93282a!important;
}
.blue{
    color: #4169E1!important;
}

.blue_light{
    color: #97bfe3!important;
}
.grey{
    color: #bfbfbf!important;
}
.orange{
    color: #D2691E!important;
}
.orange_dpm{
    color: #f8aa6a!important;
}
.blue_dpm{
    color: $dpm_color!important;
}
.red_strong{
    color: #D80A0A!important;
}
.yellow{
    color: #eec64d!important;
}
.green_dpm{
    color: #3cb371!important;
}
.purple_dpm{
    color: $logistic_color!important;
}