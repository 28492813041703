#search-btn {
  position: relative;
  cursor: default;
  @include media-breakpoint-down(md) {
    position: absolute;
    right: 50px;
    top: 25px;
  }
  .btn {
    position: absolute;
    width: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    transition: all 1s;
    right: 0;
    cursor: default;
    height: 30px;

    i.search_icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      height: 100%;
      line-height: 2;
    }

    input {
      padding-left: 10px;
      margin-right: 10px;
      width: 0;
      height: 0;
      opacity: 0;
      transition: all 1s;
    }
  }

  .btn.open {
    width: 90vw;
    min-height: 53px;
    transition: all 1s;
    background: #17a2b8;
    @include media-breakpoint-down(md) {
      margin-right: -10vw;
      z-index: 999999;
    }
    input {
      opacity: 1;
      height: 40px;
      width: 97%;
      transition: all 1s;
      @include media-breakpoint-down(md) {
        width: 94%;
      }
    }

    i.search_icon {
      width: 2%;
      margin-top: 0;
      position: relative;
      line-height: 3;
      color: white;
    }
  }

  #search_result {
    background: #fff;
    padding: 30px;
    width: 96.8%;
    margin-right: 2.9%;
    color:$txt_color_dark;
    overflow-y: auto;
    max-height: 93vh;

    @include media-breakpoint-down(md) {
      width: 93.5%;
      margin-right: 5.6%;
    }
    h2 {
      color:$txt_color_dark;
    }

    #families {
      .border {
        border-radius: 5px;
        box-shadow: 3px 0 3px #999 !important;
        padding: 5px
      }
    }
  }

  #search_result_back {
    height: 100vh;
    width: 100vw;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -9;
    background: rgba(0, 0, 0, .5);
  }
}



body.beta_mode{
  padding-top: 100px;
}
body.beta_mode .message_beta{
  padding: 5px ;
  white-space: nowrap;
  background: white;
  text-align: center;
  overflow: hidden;
}
body.beta_mode .message_beta a{
  color: #046faa;
  text-decoration: underline!important;
}

.messagedefilant  {
  -moz-animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
  animation: marquee 30s linear infinite;
}
@-moz-keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
@-webkit-keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%) }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
}