body{
    background: rgba(200, 200, 200, 0.8);
}
a{
    text-decoration: none!important;
    color: $txt_color_dark;
}
a:hover{
    text-decoration: none!important;
    color: $txt_color_dark;
}
*, h1{
    font-family: 'saira_semicondensedregular', sans-serif;
}
h2, h3, h4, h5, h6{
    font-family: 'Economica', sans-serif;
}

h2 sup svg{
    width: 20px!important;
    margin-left: 5px;
}
.table_options,
#table_options{
    position: relative;
}
#config-wrapper .table_options ul{

    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    li{
        margin: 5px 10px;
    }
}
#table_options ul{
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
}
table ul{
    padding: 0;
}
table svg{
    margin: initial!important;
}
#message_flash{
    position: absolute;
    left: calc(50% - 108px) ;
    top: 25%;
    z-index: 9;
}
#logo {
    width: 50px;
}
.hidden{
    display: none;
}
.i-big{
    font-size: 20px;
}


/*//modif bouton upload*/
#FakeInput {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 10;
        width: 50%;
        height: 100% !important;
    }
    span {
        display: inline-block;
        vertical-align: top;
        &:first-child {
            overflow: hidden;
            white-space: nowrap;
            width: 150px;
            height: 25px;
            padding-left: 5px;
            border: 1px solid $txt_color_dark;
            text-overflow: ellipsis;
            font: 100%/25px sans-serif;
            color: #666;
            border-radius: 3px;
        }
        + {
            span {
                margin-left: 5px;
                text-align: center;
                text-decoration: underline;
            }
        }
    }
    label {
        display: initial;
        width: 100%;
    }
    .new_label {
        text-decoration: none;
        cursor: pointer;
    }
    .uplaod_rules {
        width: 100%;
        font-size: 12px;
    }
}


.toggle-group .btn{
    width: initial!important;
}
.valide {
    color: #28a745;
}
.proches {
    color: #FF8C00;
}

.urgence {
    color: #c82333;
}

#member-section .valide {
    color: #28a745;
}
#member-section .proches {
    color: #FF8C00;
}

#member-section .urgence {
    color: #c82333;
}
.fs-25{
    font-size: 25px;
}
.fs-20{
    font-size: 20px;
}
.fs-18{
    font-size: 18px;
}
.border-1{
    border: solid 1px;
}
.border-2{
    border: solid 2px;
}
.border-3{
    border: solid 3px;
}
.border-4{
    border: solid 4px;
}
.border-5{
    border: solid 5px;
}
.border-dpm{
    border-color : $dpm_color!important;
}



.actions_elements_block{
    display: flex;
}
.actions_elements_block label select{
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    margin-right: 10px;
}

#periode_essai{
    position: fixed;
    bottom: 0;
    right: 0;
    border: solid 1px red;
    text-transform: uppercase;
    padding: 5px 10px;
    background: white;
    z-index: 9;
    a{
        color: red!important;
    }
}
.video_documentation .title{
    color: $dpm_color;
    font-weight: bold;
    font-size: 1.3rem
}



/*CHECKBOX*/

#organisme input[type="checkbox"]{
    position: relative;
    width: 60px!important;
    height: 20px!important;
    -webkit-appearance: none;
    background: #8b0000!important;
    outline: none;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0,0,0,.2);
    transition: .5s;
    margin: 0 5px;
    cursor: pointer;
}
#organisme input:checked[type="checkbox"]{
    background: $dpm_color !important;
}
#organisme input[type="checkbox"]:before{
    content: '';
    position: absolute;
    width: 30px!important;
    height: 20px!important;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: #fff!important;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    transition: .5s;
}
#organisme input:checked[type="checkbox"]:before{
    left: 28px!important;
}
.invalid{
    color: red;
}

/*********************LOADER*********************/
#loader{
    position: fixed;top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
}
#loader .content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.5);
    z-index: 1;
    display: flex;
    align-items: center;

}
#loader .content img{
    margin: auto;
}
