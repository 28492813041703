html {
  font-size:14px;
}

body{
  font-size: $font_size_base;
  line-height: 1.2;
  color:$txt_color_light;
  font-weight: 400;
}

a{
  color:$main_color;
}

*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
